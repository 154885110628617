import * as Yup from 'yup';

/* HELPER FUNCTIONS AND CONSTANTS */
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
const FILE_SIZE = 500000;

export const validateImageType = (value) => {
  if (value) {
    const type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_FORMATS.includes(type);
  }
};

/* SCHEMAS */
export const ProfileSchema = Yup.object().shape({
  userPhoneNumber: Yup.string()
    .min(5, 'Phone number is too short.')
    .max(70, 'Phone number is too long.')
    .required('Required'),
  userEmail: Yup.string().email('Invalid email').required('Required'),
  userUsername: Yup.string()
    .min(6, 'Username is too short.')
    .max(24, 'Username is too long.')
    .required('Required'),
});

export const SignInSchema = Yup.object().shape({
  username: Yup.string()
    .min(6, 'Username is too short.')
    .max(24, 'Username is too long.')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Password is too short.')
    .max(24, 'Password is too long.')
    .required('Required'),
});

export const CreateUserSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(5, 'Phone number is too short.')
    .max(70, 'Phone number is too long.')
    .required('Required'),
  username: Yup.string()
    .min(6, 'Username is too short.')
    .max(24, 'Username is too long.')
    .required('Required'),
  inviteCode: Yup.string()
    .min(5, 'Invite code is too short.')
    .max(70, 'Invite code is too long.')
    .required('Required'),
  password: Yup.string()
    .min(6, 'Password is too short.')
    .max(24, 'Password is too long.')
    .required('Required'),
});

export const SpaceGalleryTextSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Text is too short.')
    .max(100, 'Text is too long.')
    .required('Required'),
});

export const SpaceHeaderSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name is too short.')
    .max(50, 'Name is too long.')
    .required('Required'),
  /* file: Yup.mixed()
    .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
    .test('fileType', 'Your Error Message', (value) =>
      SUPPORTED_FORMATS.includes(value.type),
    ), */
});

export const SpaceHomeTitleSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Title is too short.')
    .max(24, 'Title is too long.')
    .required('Required'),
});

export const SpaceHomeImageSchema = Yup.object().shape({
  file: Yup.mixed()
    .test('fileSize', 'File is too large', (value) => value.size <= FILE_SIZE)
    .test('fileType', 'Your Error Message', (value) =>
      SUPPORTED_FORMATS.includes(value.type),
    ),
});

export const SpaceImagesSchema = Yup.array()
  .of(
    Yup.object().shape({
      files: Yup.mixed()
        .test(
          'fileSize',
          'File is too large',
          (value) => value.size <= FILE_SIZE,
        )
        .test('fileType', 'Your Error Message', (value) =>
          SUPPORTED_FORMATS.includes(value.type),
        ),
    }),
  )
  .test({
    message: 'At least one image must be added.',
    test: (arr) => arr.length === 0,
  });

export const SpaceButtonSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Text is too short.')
    .max(120, 'Text is too long.')
    .required('Required'),
});

export const SpaceButtonUrlSchema = Yup.object().shape({
  url: Yup.string()
    .min(3, 'Text is too short.')
    .max(120, 'Text is too long.')
    .required('Required'),
});

export const SpaceTextSchema = Yup.object().shape({
  text: Yup.string()
    .min(3, 'Text is too short.')
    .max(240, 'Text is too long.')
    .required('Required'),
});

export const SpaceTitleSchema = Yup.object().shape({
  text: Yup.string()
    .min(3, 'Text is too short.')
    .max(24, 'Text is too long.')
    .required('Required'),
});
