const ERROR_STATUS_CODES = [400, 401, 404, 500, ];
const SUCCESS_STATUS_CODES = [200, 201, 204, 304, ]

export const sendAsWsRequest = async (requestPath, requestBody) => {
  const socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/${requestPath}`);

  return new Promise((resolve, reject) => {
    socket.onopen = (event) => socket.send(JSON.stringify(requestBody));
    socket.onmessage = (event) => { 
      const data = JSON.parse(event.data);

      if (data.completed) socket.close();
      if (ERROR_STATUS_CODES.includes(data.statusCode)) return reject(data);
      if (SUCCESS_STATUS_CODES.includes(data.statusCode)) return resolve(data);
      console.log(`unsure what to do with status code: ${data.statusCode}`)
    };
  });
}
