import { useEffect, useRef, useState } from 'react';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import { Form, Formik } from 'formik';
import { SpaceTextSchema } from 'schemas';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import Modal from '../Modal';
import ModalLevel from '../ModalLevel';
import FormTextarea from 'components/common/Forms/FormTextarea';
import GenerateButton from 'components/common/Buttons/GenerateButton';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'state/store';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ModalBody from '../ModalBody';

const QuoteModal = ({
  active,
  setActive,
  title,
  content,
  columns,
  setTempText,
  tempText,
}) => {
  const ref = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(title);
  const [levelIsActive, setLevelIsActive] = useState(false);
  const userData = useStore((state) => state.user);
  const sections = useExperimentSections();

  useScrollBlock(active);

  const handleBack = () => {
    setLevelIsActive(false);
  };

  const activateSubLevel = () => {
    setLevelIsActive(true);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempText(null);
  };

  const handleHeadingSubmit = () => {
    if (levelIsActive) submitForm();

    setTimeout(() => {
      setActive(false);
    }, 1000);
  };

  const handleRegenerateTextBody = async (textContent) => {
    const response = await sections.regenerateText(userData.userId, content.rowId, textContent);
    setTempText(response.result);
  }

  const submitForm = () => {
    if (ref.current) ref.current.click();
  };

  useEffect(() => {
    if (!levelIsActive) {
      setHeaderTitle('Quote');
    } else {
      setHeaderTitle('Text');
    }
  }, [levelIsActive]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading handleBack={ handleBack } handleHeadingClose={ handleHeadingClose } handleHeadingSubmit={ handleHeadingSubmit } levelIsActive={ levelIsActive } title={ headerTitle } />

          <ModalBody activeLevel={ levelIsActive }>
            <ModalOptions active={ !levelIsActive }>
              <ModalOptionsLine title={ 'Text' } handleClick={ activateSubLevel } />
            </ModalOptions>

            <ModalLevel active={levelIsActive} columns={columns}>
              <Formik enableReinitialize={ true } initialTouched={ { text: true, } } initialValues={{ text: tempText !== null ? tempText : content?.texts[0], }} validationSchema={ SpaceTextSchema }
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[0] = values.text;
                  const response = await sections.updateTexts(
                    userData.userId,
                    content.rowId,
                    updatedTexts,
                  );
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange, }) => (
                  <Form>
                    <FormTextarea customChangeCallback={ setTempText } errors={ errors } handleChange={ handleChange } id='text' labeled={ false } title='Text' touched={ touched } type='text' />

                    <button ref={ ref } style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>

              <GenerateButton action={ () => handleRegenerateTextBody(tempText || content.texts[0]) } />
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default QuoteModal;
