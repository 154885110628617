import React from 'react';
import styles from 'assets/styles/modules/FormFieldInfo.module.scss';
import Info from 'assets/icons/Info';

const FormFieldInfo = ({title, text, icon, iconAction}) => {
  return (
    <div className={`${styles.card} ${icon ? styles.hasIcon : ''}`}>
      <div>
        <div className="sp1">{title}</div>
        <div className="font-semibold">{text}</div>
      </div>
      {icon && (
        <div className={styles.icon}>
          <Info />
        </div>
      )}
    </div>
  );
};

export default FormFieldInfo;
