import { sendAsWsRequest, } from 'utilities/websockets';
import { useStore, } from 'state/store';

const useExperimentSections = () => {
  const userData = useStore((state) => state.user);

  const createSection = async (section, templateVerticalId, position, experimentId) => {
    try {
      const body = JSON.stringify({
        experimentId: experimentId,
        position: position,
        templateType: section,
        templateVerticalId: templateVerticalId,
      });

      const options = {
        body: body,
        headers: { Authorization: userData.userId, 'Content-Type': 'application/json', },
        method: 'POST',
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/templates/`, options);

      if (!response.ok) {
        throw new Error();
      }

      const data = await response.json();

      return data.newSection;
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateImage = async (userId, pageSectionContentId, imageId) => {
    try {
      const currentImageThumb = document.querySelector(`.inputFile__thumbnail__img[id*="${imageId}"]`);
      if (!!currentImageThumb) currentImageThumb.classList.add('regenerating');
      
      const data = await sendAsWsRequest(`v1/templates/${pageSectionContentId}/images/${imageId}/regenerate`, { });
      if (!!currentImageThumb) currentImageThumb.classList.remove('regenerating');

      return data.content;
    } catch (err) {
      localStorage.setItem('message-for-user', err);
    }
  }

  const regenerateText = async (userId, pageSectionContentId, textContent) => {
    try {
      const data = await sendAsWsRequest(`v1/templates/${pageSectionContentId}/texts/${textContent}/regenerate`, { });
      return data.content;
    } catch (err) {
      localStorage.setItem('message-for-user', err);
    }
  }

  const removeSection = async (id, experimentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/templates/remove`,
        {
          body: JSON.stringify({
            id: id,
            experimentId: experimentId,
          }),
          headers: {
            Authorization: userData.userId,
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
      );

      if (!response.ok) {
        throw new Error();
      }

      const data = await response.json();

      return data.content;
    } catch (error) {
      console.log(error);
    }
  };

  const replaceImage = async (pageSectionContentId, originalImageId, newImageBase64, fileType) => {
    try {
      const currentImageThumb = document.querySelector(`.inputFile__thumbnail__img[id*="${originalImageId}"]`);
      if (!!currentImageThumb) currentImageThumb.classList.add('regenerating');
      
      const data = await sendAsWsRequest(`v1/templates/${pageSectionContentId}/images/${originalImageId}/replace`, { content: { base64: newImageBase64, fileType: fileType, }});
      if (!!currentImageThumb) currentImageThumb.classList.remove('regenerating');
      
      return data.content;
    } catch (err) {
      localStorage.setItem('message-for-user', err);
    }
  }

  const updateLogo = async (experimentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/experiments/${experimentId}`,
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'GET',
        },
      );

      if (!response.ok) {
        throw new Error();
      }

      const data = await response.json();

      return data.experiment;
    } catch (error) {
      console.log(error);
    }
  };

  const updateTexts = async (userId, optionId, texts) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/templates/${optionId}/update-texts`,
        {
          body: JSON.stringify({ texts: texts }),
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
      );

      if (!response.ok) {
        throw new Error();
      }

      const data = await response.json();

      return data.content;
    } catch (error) {
      console.log(error);
    }
  };

  const updateImages = async (userId, optionId, images, types) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/templates/${optionId}/update-images`,
        {
          body: JSON.stringify({ images: images, contentType: types }),
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        },
      );

      const data = await response.json();

      return data.content;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  return {
    createSection,
    regenerateImage,
    regenerateText,
    removeSection,
    replaceImage,
    updateLogo,
    updateTexts,
    updateImages,
  };
};

export default useExperimentSections;
