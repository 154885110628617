import styles from 'assets/styles/modules/Modal.module.scss';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import Modal from '../Modal';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ModalBody from '../ModalBody';

const DesignModal = ({ active, setActive, title }) => {
  const [currentMode, setCurrentMode] = useState('light');

  useScrollBlock(active);

  const setMode = (mode) => {
    {
      /*
    1. Call API and set new mode
    */
    }
    setCurrentMode(mode);
  };

  const handleHeadingSubmit = () => {
    // Just close the modal
    setActive(false);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
  };

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            title={title}
            handleHeadingClose={handleHeadingClose}
            handleHeadingSubmit={handleHeadingSubmit}
            levelIsActive={false}
          />
          <ModalBody activeLevel={active}>
            <ModalOptions active={active}>
              <label className='flex sp1 font-semibold mb-16'>Theme Mode</label>
              <div className={styles.themeSelector}>
                <div
                  onClick={() => setMode('Light')}
                  className={`${
                    currentMode === 'Light' ? styles.selected : ''
                  } ${styles.ctaTheme} ${styles.light}`}
                >
                  Light Mode
                </div>
                <div
                  onClick={() => setMode('Dark')}
                  className={`${
                    currentMode === 'Dark' ? styles.selected : ''
                  } ${styles.ctaTheme} ${styles.dark}`}
                >
                  Dark Mode
                </div>
              </div>
            </ModalOptions>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default DesignModal;
