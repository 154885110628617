import React from 'react';

import styles from 'assets/styles/modules/FormEditImage.module.scss';

import GenerateButton from '../Buttons/GenerateButton';
import FormImageAdd from './FormImageAdd';
import FormImageEnhance from './FormImageEnhance';

const FormEditImage = ({
  errors,
  generate,
  img,
  multiple,
  rowId,
  setFieldValue,
  setTempImage,
  touched,
}) => {
  return (
    <div className={ `${styles.container} ${multiple ? styles.multiple : ''}` }>
      <FormImageAdd errors={ errors } id='file' img={ img || null } rowId={ rowId } setFieldValue={ setFieldValue } setTempImage={ setTempImage } title='' touched={ touched } />

      <div className={ styles.col }>
        {/* <FormImageEnhance /> */}
        <GenerateButton square action={ () => !!generate ?  generate(img) : null } />
      </div>
    </div>
  );
};

export default FormEditImage;
