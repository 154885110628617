import { useEffect, useRef, useState } from 'react';

function VideoBackground({ selectedStyle }) {
  useEffect(addStyle, [selectedStyle]);
  const [loopIsPlaying, setLoopIsPlaying, ] = useState(false);
  const loopVideo = useRef(null);

  useEffect(() => {
    let timer;
    if (loopVideo.current) timer = setTimeout(() => { loopVideo.current.classList.add('loaded'); }, 1000);

    return () => { clearTimeout(timer); };
  }, [loopVideo.current]);

  function addStyle() {
    const videoContainer = document.getElementById('video-background-container');

    if (!!videoContainer) {
      ['bold', 'default', 'minimal', ].forEach((cls) => videoContainer.classList.remove(cls));
      videoContainer.classList.add(selectedStyle || 'default');
    }
  }

  function playLoop() {
    loopVideo.current.play();
    setLoopIsPlaying(true);
  }

  return (
    <div className='videoBackground' id='video-background-container'>
      {!loopIsPlaying && (
        <video id='orb-intro' onEnded={playLoop} autoPlay muted playsInline>
          <source src='https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/intro-fullscreen.mp4' type='video/mp4' />
        </video>
      )}

      <video id='orb-loop' loop muted playsInline preload='auto' ref={ loopVideo }>
        <source src='https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/loop-fullscreen.mp4' type='video/mp4' />
      </video>
    </div>
  );
}

export default VideoBackground;
