import { useEffect, useRef, useState } from 'react';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import FormField from '../../Forms/FormField';
import { Form, Formik } from 'formik';
import { SpaceHomeTitleSchema, SpaceTextSchema } from 'schemas';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import Modal from '../Modal';
import ModalLevel from '../ModalLevel';
import FormTextarea from 'components/common/Forms/FormTextarea';
import GenerateButton from 'components/common/Buttons/GenerateButton';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'state/store';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ModalBody from '../ModalBody';

const ScoreModal = ({
  active,
  setActive,
  content,
  setTempTitle,
  setTempText,
  tempTitle,
  tempText,
}) => {
  const refTitle = useRef(null);
  const refText = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState('');
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel('');
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempText(null);
    setTempTitle(null);
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === 'Title') {
      submitTitleForm();
    } else if (activeLevel === 'Text') {
      submitTextForm();
    }
    setTimeout(() => {
      setActiveLevel('');
      setActive(false);
    }, 1000);
  };

  const handleRegenerateTextBody = async (textContent) => {
    const response = await sections.regenerateText( userData.userId, content.rowId, textContent);
    setTempText(response.result);
  }

  const handleRegenerateTextTitle = async (textContent) => {
    const response = await sections.regenerateText(userData.userId, content.rowId, textContent);
    setTempTitle(response.result);
  }

  const submitTitleForm = () => {
    if (refTitle.current) refTitle.current.click();
  };

  const submitTextForm = () => {
    if (refText.current) refText.current.click();
  };

  useEffect(() => {
    if (activeLevel === '') {
      setHeaderTitle('Text');
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            handleBack={handleBack}
            handleHeadingClose={handleHeadingClose}
            handleHeadingSubmit={handleHeadingSubmit}
            levelIsActive={activeLevel !== ''}
            title={headerTitle}
          />

          <ModalBody activeLevel={activeLevel}>
            <ModalOptions active={activeLevel === ''}>
              <ModalOptionsLine handleClick={ () => activateSubLevel('Title') } title={ 'Title' } />

              <ModalOptionsLine handleClick={ () => activateSubLevel('Text') } title={ 'Text' } />
            </ModalOptions>

            {/* Title Level */}
            <ModalLevel active={activeLevel === 'Title'} columns={1}>
              <div className='mb-32'>
                <Formik initialValues={{ title: tempTitle !== null ? tempTitle : content?.texts[0], }} validationSchema={ SpaceHomeTitleSchema }
                  onSubmit={async (values) => {
                    const updatedTexts = content.texts;
                    updatedTexts[0] = values.title;
                    const response = await sections.updateTexts(
                      userData.userId,
                      content.rowId,
                      updatedTexts,
                    );
                    if (response?.success) setActive(false);
                  }}
                >
                  {({ errors, touched, handleChange, }) => (
                    <Form>
                      <FormField
                        id='title'
                        title=''
                        type='text'
                        errors={errors}
                        touched={touched}
                        labeled='false'
                        handleChange={handleChange}
                        customChangeCallback={setTempTitle}
                      />

                      <button ref={ refTitle } style={{ display: 'none' }} type='submit'>
                        <span>Save</span>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>

              <GenerateButton action={ () => handleRegenerateTextTitle(tempTitle || content.texts[0]) } />
            </ModalLevel>

            {/* Text Level */}
            <ModalLevel active={activeLevel === 'Text'} columns={1}>
              <Formik initialValues={{ text: tempText || content?.texts[1] || '', }} validationSchema={ SpaceTextSchema }
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[1] = values.text;
                  const response = await sections.updateTexts(
                    userData.userId,
                    content.rowId,
                    updatedTexts,
                  );
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange }) => (
                  <Form>
                    <FormTextarea
                      customChangeCallback={setTempText}
                      errors={errors}
                      handleChange={handleChange}
                      id='text'
                      labeled={false}
                      touched={touched}
                    />

                    <button ref={ refText } style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>

              <GenerateButton action={ () => handleRegenerateTextBody(tempText || content.texts[1]) } />
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ScoreModal;
