const ExternalLink = ({color}) => {
  return (
    <svg viewBox="0 0 13 13">
      <path
        d="m13,4.52c0,.18-.22.27-.34.14l-1.63-1.63s-.02.02-.03.03l-3.44,3.44c-.29.29-.77.29-1.06,0-.29-.29-.29-.77,0-1.06l3.44-3.44s.02-.02.03-.03l-1.63-1.63c-.13-.13-.04-.34.14-.34h4.32c.11,0,.2.09.2.2v4.32ZM.75,1c-.41,0-.75.34-.75.75v10.5c0,.41.34.75.75.75h10.5c.41,0,.75-.34.75-.75v-4.5c0-.41-.34-.75-.75-.75s-.75.34-.75.75v3.75H1.5V2.5h3.75c.41,0,.75-.34.75-.75s-.34-.75-.75-.75H.75Z"
        fill={color ? color : '#fff'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ExternalLink;
