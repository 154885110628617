import Bin from 'assets/icons/Bin';
import Edit from 'assets/icons/Edit';
import Eye from 'assets/icons/Eye';
import Graph from 'assets/icons/Graph';
import Share from 'assets/icons/Share';
import styles from 'assets/styles/modules/ProjectItemModal.module.scss';
import useExperiments from 'lib/hooks/useExperiments';
import { useNavigate } from 'react-router-dom';

const ProjectItemModal = ({ active, setActive, experiment, userId, }) => {
  const navigate = useNavigate();
  const experiments = useExperiments(userId);

  const shareData = {
    title: experiment?.title ? experiment.title : 'Paralllel',
    text: experiment?.description ? experiment.description : '',
    url: `/spaces/${experiment?._id}`,
  };

  const deleteOne = async () => {
    await experiments.deleteOne(experiment._id);
    window.location.reload();
  }

  const share = async () => {
    try {
      await navigator.share(shareData);
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        console.log('Sharing aborted.');
      }
    }
  };

  return (
    <div className={`${styles.wrapper} ${active ? styles.active : null}`}>
      <div className={`${styles.overlay} ${active ? styles.active : null} bg-blur`} onClick={() => setActive(false)}></div>
      
      <div className={`${styles.container} ${active ? styles.active : null} shadow-md`}>
        <ul className={styles.list}>
          <li onClick={() => navigate(`/spaces/${experiment._id}/edit`)}>
            <span className={styles.icon}>
              <Edit />
            </span>
            Edit
          </li>

          <li onClick={share}>
            <span className={styles.icon}>
              <Share />
            </span>
            Share
          </li>

          <li onClick={() => navigate(`/spaces/${experiment._id}`)}>
            <span className={styles.icon}>
              <Eye />
            </span>
            Preview
          </li>

          <li onClick={ deleteOne }>
            <span className={styles.icon}>
              <Bin />
            </span>
            Delete
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProjectItemModal;
