import React from 'react';
import styles from 'assets/styles/modules/SquareIcon.module.scss';

const variantStyles = (variant) => {
  switch (variant) {
    case 'outline':
      return styles.outline;
    case 'outlineInverted':
      return styles.outlineInverted;
    default:
      return styles.outline;
  }
};

const SquareIcon = ({children, variant, iconWidth}) => {
  return (
    <div className={`${styles.container} ${variantStyles(variant)}`}>
      <div className={`${iconWidth ? iconWidth : ''} ${styles.icon}`}>
        {children}
      </div>
    </div>
  );
};

export default SquareIcon;
