const Chevron = () => {
  return (
    <svg viewBox='0 0 7.92 13.49'>
      <path
        d='m5.15,6.75L.29,11.61c-.39.39-.39,1.02,0,1.41l.18.18c.39.39,1.02.39,1.41,0l5.75-5.75c.39-.39.39-1.02,0-1.41L1.88.29c-.39-.39-1.02-.39-1.41,0l-.18.18C-.1.86-.1,1.49.29,1.88l4.86,4.86Z'
        fill='#2f2f2f'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Chevron;
