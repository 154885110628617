const Eye = ({ color }) => {
  return (
    <svg viewBox='0 0 16.5 12'>
      <path
        d='m1.98,6.53c-.22-.32-.22-.74,0-1.05.49-.7,1.16-1.5,1.95-2.2,1.17-1.04,2.49-1.78,3.83-1.78s2.66.74,3.83,1.78c.78.7,1.45,1.5,1.95,2.2.22.32.22.74,0,1.05-.49.7-1.16,1.5-1.95,2.2-1.17,1.04-2.49,1.78-3.83,1.78s-2.66-.74-3.83-1.78c-.78-.7-1.45-1.5-1.95-2.2ZM7.75,0c-1.87,0-3.54,1.01-4.82,2.16C1.63,3.32.63,4.69.1,5.63c-.13.23-.13.51,0,.74.53.93,1.53,2.31,2.83,3.47,1.28,1.15,2.96,2.16,4.82,2.16s3.54-1.01,4.82-2.16c1.29-1.16,2.29-2.53,2.83-3.47.13-.23.13-.51,0-.74-.53-.93-1.53-2.31-2.83-3.47-1.28-1.15-2.96-2.16-4.82-2.16Zm0,8c1.1,0,2-.9,2-2s-.9-2-2-2-2,.9-2,2,.9,2,2,2Z'
        fill={color ? color : '#2f2f2f'}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Eye;
