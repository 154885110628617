import styles from 'assets/styles/modules/Modal.module.scss';
import { motion } from 'framer-motion';

const Modal = ({ handleClose, children }) => {
  const anim = {
    hidden: {
      opacity: 0,
      y: '40px',
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    visible: {
      opacity: 1,
      y: '0',
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <motion.div
      className={`${styles.wrapper}`}
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={anim}
    >
      <div className={styles.overlay} onClick={handleClose}></div>

      <div className={`${styles.container} shadow-md custom-scrollbar`}>
        {children}
      </div>
    </motion.div>
  );
};

export default Modal;
