const PlusLg = () => {
  return (
    <svg viewBox="0 0 34 34">
      <path
        d="m17,1v32"
        fill="none"
        stroke="#828282"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="m33,17H1"
        fill="none"
        stroke="#828282"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default PlusLg;
