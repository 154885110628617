const SectionAbout = () => {
  return (
    <svg width='56' height='86.2' viewBox='0 0 56 86.2'>
      <path
        d='m2.21,1.34H.09V.2h5.55v1.14h-2.11v5.86h-1.32V1.34Zm4.13.82h1.23v5.04h-1.23V2.16Zm1.28-2.16v1.37h-1.32V0h1.32Zm2.9.64v1.52h1.4v1.03h-1.4v2.2c0,.25.06.45.19.58.13.13.31.19.54.19h.67v1.04h-.81c-.56,0-1-.15-1.33-.46-.33-.31-.49-.75-.49-1.3v-2.25h-.99v-1.03h.99V.64h1.23Zm2.49-.64h1.22v7.2h-1.22V0Zm2.12,4.66c0-.46.09-.88.28-1.27.19-.39.47-.71.84-.95.37-.24.82-.36,1.35-.36s.98.12,1.35.35c.37.23.65.53.83.9.18.37.27.76.27,1.18,0,.15,0,.29-.02.42h-3.72c.05.41.19.73.44.97.25.24.56.36.95.36.31,0,.57-.06.77-.19.2-.13.33-.31.4-.55h1.2c-.09.53-.36.96-.79,1.28-.43.32-.96.48-1.58.48-.53,0-.99-.12-1.38-.37-.39-.25-.68-.57-.89-.97-.2-.4-.3-.83-.3-1.28Zm3.69-.54c-.03-.33-.15-.6-.37-.81-.21-.21-.5-.31-.85-.31-.71,0-1.13.37-1.26,1.12h2.48Z'
        fill='#2f2f2f'
      />
      <rect
        x='.5'
        y='12.7'
        width='55'
        height='35'
        rx='1.5'
        ry='1.5'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <path
        d='m6.95,45.27l10.38-13.16c.56-.71,1.61-.77,2.24-.13l4.64,4.67c1.12,1.12,2.98.94,3.86-.38l10.77-16.28c.59-.9,1.91-.9,2.5,0l.42-.28-.42.28,13.92,21.03c.16.25.25.53.25.83v4.35c0,.83-.67,1.5-1.5,1.5H8.12c-1.25,0-1.95-1.44-1.18-2.43Z'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <circle
        cx='10.4'
        cy='23.21'
        r='3.16'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <line
        x1='.5'
        y1='55.7'
        x2='41.5'
        y2='55.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='.5'
        y1='67.7'
        x2='41.5'
        y2='67.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='.5'
        y1='79.7'
        x2='41.5'
        y2='79.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='.5'
        y1='61.7'
        x2='41.5'
        y2='61.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='.5'
        y1='73.7'
        x2='41.5'
        y2='73.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='.5'
        y1='85.7'
        x2='41.5'
        y2='85.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default SectionAbout;
