import React, {useState} from 'react';
import SubmenuHeading from './SubmenuHeading';
import styles from 'assets/styles/modules/Submenu.module.scss';

const NotificationsSubmenu = ({selectedSubMenu, closeSubmenu}) => {
  const [userReceiveEmails, setUserReceiveEmails] = useState(true);
  const [userReceiveTexts, setUserReceiveTexts] = useState(true);

  return (
    <div
      className={`${styles.container} ${
        selectedSubMenu === 'notifications' ? styles.visible : ''
      } custom-scrollbar`}
    >
      <div className={styles.main}>
        <SubmenuHeading
          title="Notifications"
          leftCtaTitle="Back"
          leftCtaAction={closeSubmenu}
        />

        <div className="formRow formRow--checkbox">
          <label className="formRow_label toggle-label">
            E-mail Notifications
            <input
              type="checkbox"
              checked={userReceiveEmails}
              onChange={() => setUserReceiveEmails(!userReceiveEmails)}
            />
            <span className="pretty-toggle"></span>
          </label>
        </div>

        <div className="formRow formRow--checkbox">
          <label className="formRow_label toggle-label">
            Phone Notifications
            <input
              type="checkbox"
              checked={userReceiveTexts}
              onChange={() => setUserReceiveTexts(!userReceiveTexts)}
            />
            <span className="pretty-toggle"></span>
          </label>
        </div>

        <p className={styles.ctaGroup}>
          <button
            className="button button--white"
            onClick={() => {
              setUserReceiveEmails(false);
              setUserReceiveTexts(false);
            }}
          >
            Unsubscribe All
          </button>
        </p>
      </div>

      <p className="sp2 mt5">
        If you have any questions please refer to our
        <br />
        <a href="/terms-of-use">Terms of Use</a> and{' '}
        <a href="/privacy-policy">Privacy Policy</a>.
      </p>
    </div>
  );
};

export default NotificationsSubmenu;
