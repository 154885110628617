const Badge = () => {
  return (
    <svg viewBox="0 0 17.4 16.7">
      <path
        d="m5.9,5.54c-.14-.13-.2-.33-.14-.52.06-.18.23-.32.42-.34l1.46-.17.61-1.29c.08-.18.26-.29.45-.29s.37.12.45.29l.61,1.29,1.46.17c.2.02.36.15.42.34.06.18.01.39-.14.52l-1.07.96.28,1.39c.04.19-.03.38-.19.5-.09.07-.2.1-.3.1-.08,0-.16-.02-.24-.06l-1.28-.7-1.28.7c-.17.09-.38.08-.54-.04-.15-.11-.23-.31-.19-.5l.28-1.39-1.07-.96Zm11.41,9.1c-.12.22-.36.35-.61.35h-2.81l-1.65,1.52c-.13.12-.3.19-.47.19-.03,0-.07,0-.1,0-.21-.03-.39-.15-.5-.33l-2.47-4.12-2.47,4.12c-.11.18-.29.3-.5.33-.03,0-.07,0-.1,0-.17,0-.34-.07-.48-.19l-1.65-1.52H.7c-.25,0-.48-.13-.61-.35s-.12-.49,0-.7l3.21-5.48c-.48-.82-.76-1.75-.76-2.75C2.55,2.56,5.31,0,8.7,0s6.15,2.56,6.15,5.71c0,1-.28,1.93-.76,2.75l3.21,5.48c.13.22.13.48,0,.7ZM3.95,5.71c0,.99.39,1.89,1.01,2.62l.04.02h0c.87,1.01,2.2,1.67,3.71,1.67s2.84-.65,3.71-1.65h0s.04-.03.04-.03c.62-.73,1.01-1.63,1.01-2.62,0-2.38-2.13-4.31-4.75-4.31S3.95,3.33,3.95,5.71Zm3.66,5.62c-1.32-.22-2.49-.83-3.37-1.69l-2.32,3.96h1.86c.18,0,.35.07.48.19l1.22,1.12,2.14-3.58Zm7.86,2.26l-2.32-3.96c-.88.87-2.05,1.47-3.37,1.69l2.14,3.58,1.22-1.12c.13-.12.3-.19.47-.19h1.86Z"
        fill="#2f2f2f"
      />
    </svg>
  );
};

export default Badge;
