import useCustomer from 'lib/hooks/useCustomer';
import useRequireUnauthenticated from 'lib/hooks/useRequireUnauthenticated';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { SignInSchema } from 'schemas';
import FormField from 'components/common/Forms/FormField';

function SignInPage() {
  // User must be Unauthenticated to view this page
  useRequireUnauthenticated();

  const user = useCustomer();
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={SignInSchema}
      onSubmit={async (values) => {
        const response = await user.signIn(values.username, values.password);
        if (response?.success) {
          navigate(`/dashboard?section=spaces`);
        }
      }}
    >
      {({ errors, touched, handleChange }) => (
        <Form>
          <section className='main'>
            <div className='page_content'>
              <h3 className='st2'>Sign In</h3>

              <div className='form'>
                <div className='formRow'>
                  <FormField
                    id='username'
                    title='Username'
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>

                <div className='formRow'>
                  <FormField
                    id='password'
                    title='Password'
                    type='password'
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
              </div>

              <div className='flex-1 flex flex-col justify-center text-center'>
                <div className='underline mb-16'>
                  Forgot your username or password?
                </div>
                <div>
                  Don't have an account?{' '}
                  <a href='/sign-up' className='underline'>
                    Sign up
                  </a>{' '}
                  here.
                </div>
              </div>
            </div>

            <div className='page_actions'>
              <button
                className='button button--filled button--sized'
                type='submit'
              >
                <span>Sign In</span>
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
}

export default SignInPage;
