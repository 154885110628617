import Chevron from 'assets/icons/Chevron';
import React from 'react';
import styles from 'assets/styles/modules/Modal.module.scss';

const ModalOptionsLine = ({title, handleClick}) => {
  return (
    <div className={styles.optionsLine} onClick={handleClick}>
      <span className="font-semibold">{title}</span>
      <span className={styles.icon}>
        <Chevron />
      </span>
    </div>
  );
};

export default ModalOptionsLine;
