import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import Edit from 'assets/icons/Edit';
import CircleIcon from 'assets/icons/CircleIcon';
import {useState} from 'react';

const EditButton = ({openModal, position}) => {
  return (
    <div
      className={`${globalSectionStyles.edit} ${
        globalSectionStyles[position ? position : 'bottomRight']
      }`}
      onClick={() => openModal(true)}
    >
      <CircleIcon variant="filledInverted" iconWidth={'w-14'}>
        <Edit />
      </CircleIcon>
    </div>
  );
};

export default EditButton;
