import ArrowRightAngle from 'assets/icons/ArrowRightAngle';
import Plus from 'assets/icons/Plus';
import CircleIcon from 'assets/icons/CircleIcon';
import Close from 'assets/icons/Close';
import VideoBackground from 'components/createExperiment/VideoBackground';
import useCustomer from 'lib/hooks/useCustomer';
import { useNavigate, } from 'react-router-dom';
import { useStore, } from 'state/store';

function Welcome() {
  const navigate = useNavigate();
  const user = useCustomer();
  const userData = useStore((state) => state.user);

  const toggleTeaserVideo = (shouldPlay) => {
    document.getElementById('teaser-video-container').classList.toggle('shown');
    
    // return to beginning of video if it was playing in the background
    document.getElementById('teaser-video').pause();
    document.getElementById('teaser-video').currentTime = 0;

    // begin playing after trnsition finishes
    if (shouldPlay) setTimeout(() => { document.getElementById('teaser-video').play(); }, 500);
  }

  return (
    <section className="main">
      <div className="page_content page_content--middle">
        {user.state.isSignedIn() ?
            (<h2 className="st1">Hello,<br/>{userData.username}.</h2>)
          :
            (<h2 className="st1">Build and share your space.</h2>)
        }
      </div>

      <VideoBackground selectedStyle={null} />

      <div className="page_actions">
        <button className="button button--filled button--sized secondary right-icon" onClick={ () => toggleTeaserVideo(true) }>
          <span className="flex-column centered">
            <span>Learn more</span>
          </span>

          
          <span className="flex-column centered icon small">
            <ArrowRightAngle color="#2f2f2f" />
          </span>
        </button>
        
        <button className="button button--filled button--sized right-icon" onClick={() =>  navigate('/spaces/create', {state: {activeStep: 1}}) }>
          <span className="flex-row centered">
            <span className="flex-column centered">
              <span>New Space</span>
            </span>
            
            <span className="flex-column centered icon">
              <Plus />
            </span>
          </span>
        </button>
      </div>
      
      <div id="teaser-video-container">
        <span id="teaser-video-background-cover"></span>

        <span id="teaser-video-close-button" onClick={ () => toggleTeaserVideo(false) }>
          <CircleIcon variant={ 'filledInverted' } iconWidth={ 'w-14' }>
            <Close color={ '#002534' } />
          </CircleIcon>
        </span>

        <video id="teaser-video" onEnded={ () => toggleTeaserVideo(false) } playsInline>
          <source src='https://paralllel-webpage-assets.s3.eu-west-3.amazonaws.com/videos/teaser-vertical-with-sound.mp4' type='video/mp4' />
        </video>
      </div>
    </section>
  );
}

export default Welcome;
