import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/CtaSection.module.scss';
import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import Plus from 'assets/icons/Plus';
import ButtonModal from 'components/common/Modal/Button/ButtonModal';
import { useState } from 'react';
import EditButton from './ui/EditButton';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import { motion } from 'framer-motion';
import { spring } from 'utilities/animations';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';

const CtaSection = ({ content, isShowPage, openEdit, removeSection }) => {
  const [active, setActive] = useState(false);
  const [tempTitle, setTempTitle] = useState(null);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section className={globalSectionStyles.container} { ...(isShowPage ? [] : swipeHandlers) } draggable='false' style={{ userSelect: 'none' }}>
        <ButtonModal active={active} content={content} setActive={setActive} setTempTitle={setTempTitle} title='Button' />

        { openEdit &&
          <EditButton openModal={setActive} position='bottomRightNoImage' />
        }
        
        <div className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`} data-layout={layout === 0 ? 1 : layout}>
          <motion.div className={styles.cta} layout transition={spring}>
            { isShowPage ?
                <a href={ `${content?.texts?.[2] === 'email' ? 'mailto:' : content?.texts?.[2] === 'phone' ? 'tel:' : ''}` + content?.texts[1] } target="_blank">{tempTitle ? tempTitle : content?.texts[0]}</a>
              :
                <span>{tempTitle ? tempTitle : content?.texts[0]}</span>
            }
          </motion.div>
        </div>

        { openEdit &&
          <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        }

        { openEdit &&
          <div className={globalSectionStyles.addSection} onClick={() => openEdit({ status: true, afterPosition: content.position }) }>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        }
      </section>

      { openEdit &&
        <div className={globalSectionStyles.divider}></div>
      }
    </>
  );
};

export default CtaSection;
