const Megaphone = () => {
  return (
    <svg viewBox='0 0 15 13'>
      <path
        d='m13.57.07c.26.12.43.39.43.68v3.27c.6.35,1,.99,1,1.73s-.4,1.39-1,1.73v3.77c0,.28-.15.53-.4.66-.24.13-.54.12-.77-.04-.7-.47-2.01-1.19-3.57-1.8-1.52-.59-3.24-1.05-4.86-1.07l.58,3.11c.04.22-.02.45-.16.62-.14.17-.35.27-.58.27H1.75c-.36,0-.67-.25-.74-.6L.01,7.4s-.01-.1-.01-.15v-3.5c0-.41.34-.75.75-.75h3.5c1.08,0,2.63-.23,4.22-.72,1.59-.48,3.16-1.2,4.31-2.12.23-.18.53-.22.79-.09Zm-4.67,3.64c-1.38.42-2.76.67-3.91.75v3.05c1.68.11,3.36.59,4.81,1.15,1.02.4,1.95.84,2.69,1.25V2.17c-1.11.66-2.37,1.17-3.59,1.54Zm-7.41.78h2v3h-.9l.75,4h-.98l-.86-4.32v-2.68Z'
        fill='#eaeaea'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Megaphone;
