import {useNavigate} from 'react-router-dom';
import styles from 'assets/styles/modules/DashboardActions.module.scss';
import useAnimate from 'lib/hooks/useAnimate';
import {useRef, useState} from 'react';
import DesignModal from 'components/common/Modal/Design/DesignModal';
import Plus from 'assets/icons/Plus';

const EditActions = ({experimentId}) => {
  const [designModalIsOpen, setDesignModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const ref = useRef(null);

  useAnimate([ref.current], 500, styles.visible);

  return (
    <>
      <DesignModal active={ designModalIsOpen } setActive={ setDesignModalIsOpen } title="Design" />

      <div className={`${styles.container} sp1 font-semibold`} ref={ref}>
        <div className={styles.item}>
          <a className={ window.location.href.includes('edit') ? styles.selected : '' }>
            Design
          </a>
        </div>

        <div className={styles.item}>
          <button className={`${styles.icon} ${styles.plus}`} onClick={ () => navigate('/spaces/create', { state: { activeStep: 1, } }) }>
            <Plus color="#EA9331" />
          </button>
        </div>

        <div className={styles.item}>
          <a href={`/spaces/${experimentId}`}>Preview</a>
        </div>
      </div>
    </>
  );
};

export default EditActions;
