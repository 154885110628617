const Close = ({color}) => {
  return (
    <svg viewBox="0 0 8.44 8.44">
      <path
        d="m4.22,3.16L7.16.22c.29-.29.77-.29,1.06,0,.29.29.29.77,0,1.06l-2.94,2.94,2.94,2.94c.29.29.29.77,0,1.06s-.77.29-1.06,0l-2.94-2.94-2.94,2.94c-.29.29-.77.29-1.06,0-.29-.29-.29-.77,0-1.06l2.94-2.94L.22,1.28C-.07.99-.07.51.22.22.51-.07.99-.07,1.28.22l2.94,2.94Z"
        fill={color ? color : '#fff'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Close;
