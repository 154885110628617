const elements = require('./elements');
const routes = require('./routes');
const text = require('./text');
const user = require('./user');
const websockets = require('./websockets');

const utilities = {
  elements,
  routes,
  text,
  user,
  websockets,
}

export default utilities;
