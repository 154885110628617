import { FFmpeg, } from "@ffmpeg/ffmpeg";
import { fetchFile } from '@ffmpeg/util';


export const convertVideoToGif = async (video) => { 
  console.log(video)

  const ffmpeg = new FFmpeg();
  await ffmpeg.load();

  // uncomment this to get console logs for debugging
  // ffmpeg.on("log", ({ type, message }) => {
  //   console.log(message)
  // })

  // Write the .mp4 to the FFmpeg file system 
  const writeRes = await ffmpeg.writeFile("in.mp4", await fetchFile(video));
    

  // Run the FFmpeg command-line tool, converting the .mp4 into .gif file 
  const execRes = await ffmpeg.exec(["-i",  "in.mp4", "-t", "3.5", "-ss", "2.0", "-f", "gif", "out.gif", ]);


  // Read the .gif file back from the FFmpeg file system 
  const data = await ffmpeg.readFile("out.gif");

  const b64 = await bufferToBase64(data);

  return b64;


  // this is probably a better way to pass around the object, rather than a big blob
  // const url = URL.createObjectURL( 
  //   new Blob([data.buffer], { type: "image/gif" })
  // );

  // return url; 
};


// note: `buffer` arg can be an ArrayBuffer or a Uint8Array
async function bufferToBase64(buffer) {
  // use a FileReader to generate a base64 data URI:
  const base64url = await new Promise(r => {
    const reader = new FileReader()
    reader.onload = () => r(reader.result)
    reader.readAsDataURL(new Blob([buffer]))
  });
  // remove the `data:...;base64,` part from the start
  return base64url.slice(base64url.indexOf(',') + 1);
}
