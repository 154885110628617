const SectionQuote = () => {
  return (
    <svg width='48.65' height='24.2' viewBox='0 0 48.65 24.2'>
      <path
        d='m1.62.95c-.14,0-.25.05-.34.15-.08.09-.12.22-.12.39v1.17H0v-1.27C0,.98.12.64.36.39c.25-.26.57-.39.96-.39h.48v.95h-.18Zm.54.44c0-.41.12-.75.36-1,.25-.26.57-.39.96-.39h.48v.95h-.18c-.14,0-.25.05-.34.15-.08.09-.12.22-.12.39v1.17h-1.16v-1.27Z'
        fill='#2f2f2f'
      />
      <line
        x1='6.15'
        y1='5.7'
        x2='48.15'
        y2='5.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='6.15'
        y1='17.7'
        x2='48.15'
        y2='17.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='6.15'
        y1='11.7'
        x2='48.15'
        y2='11.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
      <line
        x1='6.15'
        y1='23.7'
        x2='48.15'
        y2='23.7'
        fill='none'
        stroke='#2f2f2f'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default SectionQuote;
