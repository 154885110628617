function CreationFormNavigation({ activeStep }) {
  return (
    <nav className={ `formNav ${activeStep === 0 ? 'hidden' : activeStep === 7 ? 'hidden' : ''}` } data-step={ activeStep }>
      <div className="formNav_slider">
        <ul>
          <li className={ `formNav_step ${activeStep === 0 ? 'active' : ''} formNav_step--first` } data-step="0"></li>
          <li className={ `formNav_step ${activeStep === 1 ? 'active' : ''}` } data-step="1">01</li>
          <li className={ `formNav_step ${activeStep === 2 ? 'active' : ''}` } data-step="2">02</li>
          <li className={ `formNav_step ${activeStep === 3 ? 'active' : ''}` } data-step="3">03</li>
          <li className={ `formNav_step ${activeStep === 4 ? 'active' : ''}` } data-step="4">04</li>
          <li className={ `formNav_step ${activeStep === 5 ? 'active' : ''} formNav_step--last` } data-step="5"></li>
        </ul>
      </div>
    </nav>
  );
}

export default CreationFormNavigation;
