import { useEffect } from 'react';
import { useStore } from 'state/store';

const useCustomer = () => {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);

  const setUserState = (userData) => {
    setUser({
      userId: userData._id,
      email: userData.email,
      username: userData.username,
      phoneNumber: userData.phoneNumber,
      avatar: userData.avatar,
    });
  };

  const isSignedIn = () => {
    return user.userId !== null && user.userId !== undefined;
  };

  const signOut = () => {
    setUser({
      userId: null,
      email: null,
      username: null,
      phoneNumber: null,
      avatar: null,
    });
    window.location.reload();
  };

  const signIn = async (username, password) => {
    try {
      const data = JSON.stringify({ username, password });
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/authenticate`,
        {
          body: data,
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        },
      );
      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const userData = await response.json();

      // Update State
      setUserState(userData);

      return { success: true };
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const updateUser = async (values) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/${user.userId}`,
        {
          body: JSON.stringify({
            email: values.userEmail,
            phoneNumber: values.userPhoneNumber,
            username: values.userUsername,
          }),
          headers: { 'Content-Type': 'application/json' },
          method: 'PUT',
        },
      );

      if (!response.ok) {
        throw new Error(
          'There was an issue while creating user. Please try again',
        );
      }

      const userData = await response.json();

      setUserState(userData);

      localStorage.setItem('message-for-user', 'Profile updated.');
      return { success: true };
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const createUser = async (values) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users`,
        {
          body: JSON.stringify({
            inviteCode: values.inviteCode,
            password: values.password,
            phoneNumber: values.phoneNumber,
            username: values.username,
          }),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        },
      );

      if (!response.ok) {
        throw new Error(
          'There was an issue while updating your details. Please try again',
        );
      }

      const userData = await response.json();

      setUserState(userData);

      return { success: true };
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const getMetaCounts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/${user.userId}`,
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'GET',
        },
      );

      if (!response.ok) {
        throw new Error(
          'There was an issue while getting favorites count. Please try again',
        );
      }

      const data = await response.json();

      return data.meta;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/${userId}`,
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'GET',
        },
      );

      if (!response.ok) {
        throw new Error(
          'There was an issue while fetching user. Please try again',
        );
      }

      const data = await response.json();

      return data.user;
    } catch (error) {
      console.log(error);
    }
  };

  const updateAvatar = async (base64, type, userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/upload-avatar`,
        {
          body: JSON.stringify({ base64: base64, contentType: type }),
          headers: {
            Authorization: userId,
            'Content-Type': 'application/json',
          },
          method: 'POST',
        },
      );

      const data = await response.json();

      setUser({ ...user, avatar: data.user.avatar });

      return data;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  const getAvatar = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/users/${user.userId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        },
      );

      const data = await response.json();

      setUser({ ...user, avatar: data.user.avatar });

      return data.user.avatar;
    } catch (error) {
      localStorage.setItem('message-for-user', error);
    }
  };

  /* Upon mount, check to see ... */
  useEffect(() => {}, []);

  /* Provide a simpler state */
  const state = {
    user,
    isSignedIn,
  };

  return {
    state,
    signIn,
    signOut,
    updateUser,
    createUser,
    getMetaCounts,
    fetchUser,
    updateAvatar,
    getAvatar,
  };
};

export default useCustomer;
