import { useEffect, useRef, useState } from 'react';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import FormField from '../../Forms/FormField';
import { Form, Formik } from 'formik';
import { SpaceButtonSchema, SpaceButtonUrlSchema } from 'schemas';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import Modal from '../Modal';
import ModalLevel from '../ModalLevel';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'state/store';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ModalBody from '../ModalBody';

const ButtonModal = ({ active, setActive, content, setTempTitle }) => {
  const refTitle = useRef(null);
  const refUrl = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState('');
  const [selectedCheckbox, setSelectedCheckbox] = useState(content?.texts?.[2] || '');
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel('');
    setTempTitle(null);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempTitle(null);
    setActiveLevel('');
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === 'Button Title') {
      // User is on sublevel, therefore we must send the form data to update the header
      submitForm();
    } else if (activeLevel === 'Button URL') {
      submitUrlForm();
    }
    setTimeout(() => {
      setActiveLevel('');
      setActive(false);
    }, 1000);
  };

  const submitForm = () => {
    if (refTitle.current) refTitle.current.click();
  };

  const submitUrlForm = () => {
    if (refUrl.current) refUrl.current.click();
  };

  useEffect(() => {
    if (activeLevel === '') {
      setHeaderTitle('Button');
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading handleBack={ handleBack } handleHeadingClose={ handleHeadingClose } handleHeadingSubmit={ handleHeadingSubmit } levelIsActive={ activeLevel !== '' } title={ headerTitle } />

          <ModalBody activeLevel={activeLevel}>
            <ModalOptions active={activeLevel === ''}>
              <ModalOptionsLine handleClick={ () => activateSubLevel('Button Title') } title={ 'Title' } />

              <ModalOptionsLine handleClick={ () => activateSubLevel('Button URL') } title={'URL'} />
            </ModalOptions>

            {/* Title Level */}
            <ModalLevel active={activeLevel === 'Button Title'} columns={1}>
              <Formik initialValues={{ title: content.texts[0] || '', }} validationSchema={SpaceButtonSchema}
                onSubmit={async (values) => {
                  let rowId = content.rowId;
                  const updatedTexts = content.texts;
                  updatedTexts[0] = values.title;

                  const response = await sections.updateTexts(userData.userId, rowId, updatedTexts);
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange }) => (
                  <Form>
                    <FormField customChangeCallback={ setTempTitle } errors={ errors } handleChange={ handleChange } id='title' labeled='false' title='Title' touched={ touched } type='text' />
                    
                    <button ref={ refTitle } style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>

            {/* Button Level */}
            <ModalLevel active={activeLevel === 'Button URL'} columns={2}>
              <Formik initialValues={{ url: content.texts[1] || '', }} validationSchema={ SpaceButtonUrlSchema }
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[1] = values.url;
                  updatedTexts[2] = selectedCheckbox;
                  const response = await sections.updateTexts(userData.userId, content.rowId, updatedTexts);
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange }) => (
                  <Form>
                    <div className='formRow formRow--checkbox'>
                      <label className='formRow_label toggle-label'>
                        URL

                        <input checked={selectedCheckbox === 'url'} onChange={ () => setSelectedCheckbox(selectedCheckbox === '' ? 'url' : '') } type='checkbox' />

                        <span className='pretty-toggle'></span>
                      </label>
                    </div>

                    {selectedCheckbox === 'url' && (
                      <FormField errors={errors} handleChange={handleChange} id='url' labeled={true} placeholder='https://...' touched={touched} type='text' />
                    )}

                    <div className='formRow formRow--checkbox'>
                      <label className='formRow_label toggle-label'>
                        Email
                        
                        <input checked={ selectedCheckbox === 'email' } onChange={ () => setSelectedCheckbox(selectedCheckbox === '' ? 'email' : '') } type='checkbox' />

                        <span className='pretty-toggle'></span>
                      </label>
                    </div>

                    {selectedCheckbox === 'email' && (
                      <FormField errors={errors} handleChange={handleChange} id='url' labeled={true} placeholder='name@mail.com' touched={touched} type='text' />
                    )}

                    <div className='formRow formRow--checkbox'>
                      <label className='formRow_label toggle-label'>
                        Phone Number
                        
                        <input checked={ selectedCheckbox === 'phone' } onChange={ () => setSelectedCheckbox(selectedCheckbox === '' ? 'phone' : '') } type='checkbox' />

                        <span className='pretty-toggle'></span>
                      </label>
                    </div>

                    {selectedCheckbox === 'phone' && (
                      <FormField errors={errors} handleChange={handleChange} id='url' labeled={true} placeholder='+1 111 11111' touched={touched} type='text' />
                    )}

                    <button ref={ refUrl }  style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ButtonModal;
