import HeaderModal from 'components/common/Modal/Header/HeaderModal';
import styles from 'assets/styles/modules/NavBarSection.module.scss';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { spring } from 'utilities/animations';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import EditButton from './ui/EditButton';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';

const NavBarSection = ({ content, isShowPage, }) => {
  const [active, setActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [tempTitle, setTempTitle] = useState(null);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  const toggleMenu = () => {
    if (!isShowPage) return;
    setMenuOpen(!menuOpen);
  }

  return (
    <section { ...(isShowPage ? [] : swipeHandlers) } className={ isShowPage ? styles.showSection : styles.section} draggable='false' style={{ userSelect: 'none' }}>
      <HeaderModal active={active} content={content} setActive={setActive} setTempTitle={setTempTitle} title='Header/Logo' />

      { !isShowPage &&
        <EditButton openModal={setActive} position='bottomRightNoImage' />
      }

      <div className={`${styles.container} ${menuOpen ? styles.menuOpen : ''}`} data-layout={layout === 0 ? 1 : layout}>
        <motion.div className={styles.contentAlign} layout transition={spring}>
          {content?.texts && (
            <motion.div className={`sp2 font-semibold uppercase`} layout transition={spring}>
              { tempTitle ? tempTitle : content.texts[0] }
            </motion.div>
          )}

          <motion.div className={styles.menuIcon} layout onClick={ toggleMenu } transition={spring}>
            <span></span>

            <span></span>
          </motion.div>
        </motion.div>

        <div className={ styles.menuContents }>
          {/* TODO put real menu content in here (i.e. creator)  */}
        </div>
      </div>
    </section>
  );
};

export default NavBarSection;
