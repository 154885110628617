import { useEffect, useState, } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStore, } from 'state/store';

import useExperiments from 'lib/hooks/useExperiments';
import useRequireAuthenticated from 'lib/hooks/useRequireAuthenticated';

import styles from 'assets/styles/modules/Dashboard.module.scss';

import DashboardActions from './DashboardActions';
import DashboardBadgesCard from './DashboardBadgesCard';
import DashboardEmptyCard from './DashboardEmptyCard';
import DashboardFavoritesCard from './DashboardFavoritesCard';
import DashboardSpacesCard from 'components/dashboard/DashboardSpacesCard';
import DashboardStatus from './DashboardStatus';

const Dashboard = () => {
  const [favorites, setFavorites] = useState(null);
  const [favoritesRecordLimit, setFavoritesRecordLimit] = useState(5);
  const userData = useStore((state) => state.user);
  const userExperiments = useExperiments(userData.userId);
  const [activeSection, setActiveSection] = useState(null); // spaces / badges / favorites
  const [loaded, setLoaded] = useState(false);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [queryParameters, setQueryParams] = useSearchParams();
  const [spaces, setSpaces] = useState(null);
  const [spacesCount, setSpacesCount] = useState(0);
  const [spacesRecordLimit, setSpacesRecordLimit] = useState(5);

  const navigate = useNavigate();
  useRequireAuthenticated();

  useEffect(() => {
    const sectionOnPageLoad = (new URLSearchParams(window.location.search)).get('section');
    setQueryParams((params) => {
      params.set('section', sectionOnPageLoad);
      return params;
    });
    setActiveSection(sectionOnPageLoad);
  }, []);

  // Set active section based on query params
  useEffect(() => {
    if (queryParameters) setActiveSection(queryParameters.get('section') || 'spaces');
  }, [queryParameters, ]);

  useEffect(() => {
    if (activeSection !== null)
      switch (activeSection) {
        case 'spaces':
          if(!spaces) setLoaded(false);

          userExperiments.fetchUserExperiments(spacesRecordLimit).then((data) => {
            setSpaces(data);
            setQueryParams((params) => {
              params.set('section', 'spaces');
              return params;
            });
            
            setLoaded(true);
          });
          break;

        case 'badges':
          // nothing to do yet
          break;

        case 'favorites':
          if(!favorites) setLoaded(false);

          userExperiments.fetchUserFavorites().then((data) => {
            setFavorites(data);
            setQueryParams((params) => {
              params.set('section', 'favorites');
              return params;
            });
            
            setLoaded(true);
          });
          break;

        default:
          if(!spaces) setLoaded(false);
          
          userExperiments.fetchUserExperiments(5).then((data) => {
            setSpaces(data);
            setQueryParams((params) => {
              params.set('section', 'spaces');
              return params;
            });
            
            setLoaded(true);
          });
          break;
      }
  }, [activeSection, favoritesRecordLimit, spacesRecordLimit, ]);

  return (
    <section className='main'>
      <div className={styles.container}>
        <DashboardStatus
          setActiveSection={setActiveSection}
          activeSection={activeSection}
          favoritesCount={favoritesCount}
          setFavoritesCount={setFavoritesCount}
          setSpacesCount={ setSpacesCount }
          spacesCount={ spacesCount }
        />
        {
          {
            spaces: (
              <>
                {spaces?.length > 0 && loaded ? (
                  <div className={styles.slider}>
                    <div className={styles.projects}>
                      {spaces?.map((space, rowIndex) => {
                        return <DashboardSpacesCard experiment={space} key={rowIndex} rowIndex={rowIndex} />;
                      })}

                      {spaces.length < spacesCount &&
                        <button className={ styles.loadMore } onClick={ () => setSpacesRecordLimit(spacesRecordLimit + 5) }>
                          Load More
                        </button>
                      }
                    </div>
                  </div>
                ) : (
                  <div className={styles.main}>
                    {loaded && (
                      <DashboardEmptyCard handleClick={ () => navigate('/spaces/create', { state: { activeStep: 1, }, }) } iconName='Plus' text='Share your story. Showcase your work. Build your community.' title='Create your first Space' />
                    )}
                  </div>
                )}
              </>
            ),
            badges: (
              <>
                <div className={styles.slider}>
                  <div className={styles.projects}>
                    <DashboardBadgesCard />
                  </div>
                </div>
              </>
            ),
            favorites: (
              <>
                {favorites?.length > 0 && loaded ? (
                  <div className={styles.slider}>
                    <div className={styles.projects}>
                      {favorites?.map((favorite, rowIndex) => {
                        return (
                          <DashboardFavoritesCard
                            experiment={favorite}
                            rowIndex={rowIndex}
                            key={rowIndex}
                            sectionExperiments={favorites}
                            setSectionExperiments={setFavorites}
                            setFavoritesCount={setFavoritesCount}
                          />
                        );
                      })}

                      {favorites.length < favoritesCount &&
                        <button className={ styles.loadMore } onClick={ () => setFavoritesRecordLimit(favoritesRecordLimit + 5) }>
                          Load More
                        </button>
                      }
                    </div>
                  </div>
                ) : (
                  <div className={styles.main}>
                    {loaded && (
                      <DashboardEmptyCard handleClick={ () => navigate('/feed') } iconName='Arrow' iconWidth={ 'w-24' } text='Discover Spaces from other community members.' title='Explore the Feed' />
                    )}
                  </div>
                )}
              </>
            ),
          }[activeSection]
        }
      </div>

      <DashboardActions />
    </section>
  );
};

export default Dashboard;
