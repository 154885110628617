const CheckCircle = () => {
  return (
    <svg viewBox="0 0 16 16">
      <path
        d="m1.5,8c0-3.59,2.91-6.5,6.5-6.5s6.5,2.91,6.5,6.5-2.91,6.5-6.5,6.5S1.5,11.59,1.5,8ZM8,0C3.58,0,0,3.58,0,8s3.58,8,8,8,8-3.58,8-8S12.42,0,8,0Zm3.52,6.56c.29-.3.27-.77-.02-1.06-.3-.29-.77-.27-1.06.02l-3.71,3.89-1.17-1.22c-.29-.3-.76-.31-1.06-.02-.3.29-.31.76-.02,1.06l1.53,1.6c.39.41,1.05.41,1.45,0l4.07-4.27Z"
        fill="#2f2f2f"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CheckCircle;
