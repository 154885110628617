import {useCallback, useEffect, useState} from 'react';
import {useStore} from 'state/store';
import useExperiments from 'lib/hooks/useExperiments';
import ProjectItem from 'components/common/Project/ProjectItem';
import styles from 'assets/styles/modules/Feed.module.scss';
import DashboardActions from './DashboardActions';

const Feed = () => {
  const [feedItems, setFeedItems] = useState(null);
  const [feedItemsLimit, setFeedItemsLimit] = useState(5);
  const userData = useStore((state) => state.user);
  const experiments = useExperiments(userData.userId);

  useEffect(() => {
    experiments.fetchFeedExperiments(feedItemsLimit).then((data) => {
      setFeedItems(data);
    });
  }, [feedItemsLimit, ]);

  return (
    <section className="main scroll">
      <div className={styles.container}>
        {feedItems && (
          <div>
            {feedItems.map((feedItem, rowIndex) => {
              return <ProjectItem experiment={ feedItem } key={ rowIndex } rowIndex={ rowIndex } />;
            })}

            <button className={ styles.loadMore } onClick={ () => setFeedItemsLimit(feedItemsLimit + 5) }>
              Load More
            </button>
          </div>
        )}
      </div>
      <DashboardActions />
    </section>
  );
};

export default Feed;
