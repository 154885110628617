const HeartFilled = () => {
  return (
    <svg viewBox="0 0 12.58 10.61">
      <path
        d="m11.62.91c-1.25-1.21-3.44-1.19-4.66,0l-.67.66-.67-.66C4.38-.3,2.2-.3.96.91.34,1.52,0,2.32,0,3.15c-.01.86.33,1.67.96,2.28l5.33,5.18,5.33-5.18c.62-.61.96-1.41.96-2.25s-.34-1.66-.96-2.27Z"
        fill="#2f2f2f"
      />
    </svg>
  );
};

export default HeartFilled;
