const Grid = () => {
  return (
    <svg viewBox='0 0 14 14'>
      <path
        d='m.75,0c-.41,0-.75.34-.75.75v4.5c0,.41.34.75.75.75h4.5c.41,0,.75-.34.75-.75V.75c0-.41-.34-.75-.75-.75H.75Zm.75,4.5V1.5h3v3H1.5Zm-.75,3.5c-.41,0-.75.34-.75.75v4.5c0,.41.34.75.75.75h4.5c.41,0,.75-.34.75-.75v-4.5c0-.41-.34-.75-.75-.75H.75Zm.75,4.5v-3h3v3H1.5ZM8,.75c0-.41.34-.75.75-.75h4.5c.41,0,.75.34.75.75v4.5c0,.41-.34.75-.75.75h-4.5c-.41,0-.75-.34-.75-.75V.75Zm1.5.75v3h3V1.5h-3Zm-.75,6.5c-.41,0-.75.34-.75.75v4.5c0,.41.34.75.75.75h4.5c.41,0,.75-.34.75-.75v-4.5c0-.41-.34-.75-.75-.75h-4.5Zm.75,4.5v-3h3v3h-3Z'
        fill='#262626'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Grid;
