import styles from 'assets/styles/modules/DashboardFavoritesCard.module.scss';
import {useNavigate} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import useExperiments from 'lib/hooks/useExperiments';
import {useStore} from 'state/store';
import useAnimate from 'lib/hooks/useAnimate';
import DashboardCardIframe from 'components/dashboard/DashboardCardIframe';
import ArrowRightAngle from 'assets/icons/ArrowRightAngle';
import DashboardCard from './DashboardCard';
import Avatar from 'components/common/Avatar';
import Heart from 'assets/icons/Heart';
import Bookmark from 'assets/icons/Bookmark';
import ExternalLink from 'assets/icons/ExternalLink';
import BookmarkFilled from 'assets/icons/BookmarkFilled';
import HeartFilled from 'assets/icons/HeartFilled';

const DashboardFavoritesCard = ({
  experiment,
  rowIndex,
  sectionExperiments,
  setSectionExperiments,
  setFavoritesCount,
}) => {
  const actions = useRef(null);
  const publishedButton = useRef(null);
  const title = useRef(null);
  const navigate = useNavigate();
  const [experimentData, setExperimentData] = useState(null);
  const userData = useStore((state) => state.user);
  const experiments = useExperiments(userData.userId);
  const [like, setLike] = useState(false);
  const [favorite, setFavorite] = useState(false);

  useAnimate(
    [actions.current, publishedButton.current, title.current],
    500,
    styles.visible,
  );

  useEffect(() => {
    experiments.fetchExperiment(experiment._id).then((data) => {
      setExperimentData(data);
    });
  }, [experiment]);

  const shareData = {
    title: experimentData?.title ? experimentData.title : 'Unknown Title',
    text: experimentData?.description ? experimentData.description : 'Unknown Description',
    url: `/spaces/${experimentData?._id}`,
  };

  // On load, check if user liked this project
  useEffect(() => {
    experimentData?.likedBy?.includes(userData.userId)
      ? setLike(true)
      : setLike(false);
    experimentData?.favoritedBy?.includes(userData.userId)
      ? setFavorite(true)
      : setFavorite(false);
  }, [experimentData]);

  const handleLike = async () => {
    const response = await experiments.like(experimentData._id);

    if (response?.likes?.includes(experimentData._id)) {
      setLike(true);
    } else {
      setLike(false);
    }
  };

  const share = async () => {
    try {
      await navigator.share(shareData);
    } catch (e) {
      if (e.toString().includes('AbortError')) {
        console.log('Sharing aborted.');
      }
    }
  };

  const handleFavorite = async () => {
    // Quickly update state for instant icon toggle response
    setFavorite((favorite) => !favorite);

    // Remove the experiment from the favorites list
    const updatedSectionExperiments = sectionExperiments.filter((exp) => {
      return exp._id !== experimentData._id;
    });
    setFavoritesCount(updatedSectionExperiments.length);
    setSectionExperiments(updatedSectionExperiments);

    // Update our experiment
    const response = await experiments.favorite(experimentData._id);

    if (response?.favorites?.includes(experimentData._id)) {
      setFavorite(true);
    } else {
      setFavorite(false);
    }
  };

  return (
    <DashboardCard>
      <div className={styles.header} ref={title}>
        <div className={`${styles.heading} flex items-center justify-between`}>
          <h2 className={`sp1 font-semibold flex-1 ${styles.title}`}>
            {experimentData?.title ? experimentData.title : 'Untitled'}
          </h2>
          <div
            className={`${styles.actions}`}
            onClick={() => navigate(`/spaces/${experiment._id}`)}
            ref={actions}
          >
            <ArrowRightAngle color={'#262626'} />
          </div>
        </div>
        <div className="mb-10">
          <Avatar
            name={experimentData?.creator?.username}
            img={experimentData?.creator?.avatar}
            small
          />
        </div>
      </div>
      <div className={styles.outliner} key={experiment.previewId}>
        <DashboardCardIframe experiment={experiment} rowIndex={rowIndex} />
        <div className={styles.iconList}>
          <div className={styles.iconItem} onClick={handleLike}>
            <div className={`${styles.icon} w-16`}>
              {like ? <HeartFilled /> : <Heart />}
            </div>
          </div>
          <div className={styles.iconItem} onClick={handleFavorite}>
            <div className={`${styles.icon} w-12`}>
              {favorite ? <BookmarkFilled /> : <Bookmark />}
            </div>
          </div>
          <div className={styles.iconItem} onClick={share}>
            <div className={`${styles.icon} w-12`}>
              <ExternalLink color={'#2f2f2f'} />
            </div>
          </div>
        </div>
      </div>
    </DashboardCard>
  );
};

export default DashboardFavoritesCard;
