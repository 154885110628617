const Info = () => {
  return (
    <svg viewBox='0 0 16 16'>
      <path
        d='m8,14.5c3.59,0,6.5-2.91,6.5-6.5S11.59,1.5,8,1.5,1.5,4.41,1.5,8s2.91,6.5,6.5,6.5Zm0,1.5c4.42,0,8-3.58,8-8S12.42,0,8,0,0,3.58,0,8s3.58,8,8,8Zm-.55-7.68h-1.25v-1.5h2.75v3.5h1.25v1.5h-4v-1.5h1.25v-2Zm.42-2.4c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1Z'
        fill='#262626'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Info;
