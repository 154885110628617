import { useCallback, useRef, useState } from 'react';
import styles from 'assets/styles/modules/Avatar.module.scss';
import useAnimate from 'lib/hooks/useAnimate';
import useCustomer from 'lib/hooks/useCustomer';
import { useNavigate } from 'react-router-dom';

const Avatar = ({ name, date, small, img }) => {
  const user = useCustomer();
  const navigate = useNavigate();
  const [containerRef, setcontainerRef] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setcontainerRef(node);
    }
  }, []);

  const handleClick = () => {
    if (user.state.user.username == name) {
      navigate('/dashboard?section=spaces');
    }
  };

  useAnimate([containerRef], 200, styles.visible);

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.imageContainer}>
        {img ? <img src={img} alt='Paralllel Avatar' /> : <></>}
      </div>
      <div className={styles.data}>
        {name && (
          <div
            className={`${small ? 'sp2' : ''} font-semibold`}
            onClick={handleClick}
          >
            {name}
          </div>
        )}
        {date && <div className='sp3'>{date}</div>}
      </div>
    </div>
  );
};

export default Avatar;
