import { useEffect } from 'react';

const useScrollBlock = (active) => {
  useEffect(() => {
    const container = document.querySelector('.main.scroll');
    if (active) {
      if (container) {
        container.style.overflowY = 'hidden';
      }
    } else {
      if (container) {
        container.style = '';
      }
    }
  }, [active]);
};

export default useScrollBlock;
