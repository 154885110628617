import Badge from 'assets/icons/Badge';
import DashboardStatusItem from './DashboardStatusItem';
import styles from 'assets/styles/modules/DashboardStatus.module.scss';
import Grid from 'assets/icons/Grid';
import Bookmark from 'assets/icons/Bookmark';
import useCustomer from 'lib/hooks/useCustomer';
import { useEffect, useRef, useState } from 'react';
import useAnimate from 'lib/hooks/useAnimate';

const DashboardStatus = ({
  activeSection,
  favoritesCount,
  setActiveSection,
  setFavoritesCount,
  setSpacesCount,
  spacesCount,
}) => {
  const user = useCustomer();
  const ref = useRef(null);

  useAnimate([ref.current], 500, styles.visible);

  const getMetaCounts = async () => {
    const response = await user.getMetaCounts();
    setFavoritesCount(response.favoritesCount);
    setSpacesCount(response.spacesCount);
  };

  useEffect(() => {
    getMetaCounts();
  }, []);

  return (
    <div className={`${styles.container}`} ref={ref}>
      <DashboardStatusItem
        title='Badges'
        subtitle='1'
        iconWidth='w-18'
        section={'badges'}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      >
        <Badge />
      </DashboardStatusItem>

      <DashboardStatusItem
        title='Spaces'
        subtitle={spacesCount}
        iconWidth='w-14'
        section={'spaces'}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      >
        <Grid />
      </DashboardStatusItem>
      
      <DashboardStatusItem
        title='Favorites'
        subtitle={favoritesCount}
        iconWidth='w-12'
        section={'favorites'}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      >
        <Bookmark />
      </DashboardStatusItem>
    </div>
  );
};

export default DashboardStatus;
