import React, { useEffect, useRef, useState, } from 'react';
import { Formik, Form, } from 'formik';
import { useStore, } from 'state/store';

import useAvatar from 'lib/hooks/useAvatar';
import useCustomer from 'lib/hooks/useCustomer';
import { resizeFile, } from 'utilities/resizeFile';
import { ProfileSchema, } from '../../../schemas';

import styles from 'assets/styles/modules/Submenu.module.scss';

import FormField from '../Forms/FormField';
import SubmenuHeading from './SubmenuHeading';

const ProfileEditSubmenu = ({selectedSubMenu, setSelectedSubMenu}) => {
  const avatar = useAvatar();
  const ref = useRef(null);
  const file = useRef(null);
  const user = useCustomer();
  const userData = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [formData, setFormData] = useState(null);

  const submitForm = () => {
    if (ref.current) ref.current.click();
  };

  const openImageDialog = () => {
    file.current.click();
  };

  useEffect(() => {
    if (userData)
      setFormData({
        userEmail: userData.email,
        userId: userData.userId,
        userPhoneNumber: userData.phoneNumber,
        userUsername: userData.username,
      });
  }, [userData]);

  return (
    <div className={`${styles.container} ${selectedSubMenu === 'profileEdit' ? styles.visible : ''} custom-scrollbar`}>
      {formData && (
        <Formik
          initialValues={{
            file: null,
            userEmail: formData.userEmail,
            userPhoneNumber: formData.userPhoneNumber,
            userUsername: formData.userUsername,
          }}
          validationSchema={ProfileSchema}
          onSubmit={async (values) => {
            const base64 = values.file ? await resizeFile(values.file) : null;
            user.updateUser(values);
            if (values.file) user.updateAvatar(base64, values.file.type, formData.userId);
            setSelectedSubMenu('profile');
          }}
        >
          {({errors, touched, setFieldValue, handleChange}) => (
            <>
              <SubmenuHeading leftCtaTitle="Cancel" rightCtaAction={ submitForm } rightCtaTitle="Done" title="Profile"
                leftCtaAction={async () => {
                  setSelectedSubMenu('profile');
                  await user.getAvatar();
                }}
              />

              <div className={styles.avatarContainer}>
                <div className={styles.avatar}>
                  { avatar && <img src={ avatar } alt="Paralllel Avatar" /> }
                </div>

                <div className={`${styles.avatar__edit} sp1`} onClick={ openImageDialog }>
                  Edit
                </div>

                <input accept="image/*" id="file" name="file" ref={ file } style={{ display: 'none' }} type="file"
                  onChange={async (e) => {
                    // Object is possibly null error w/o check
                    if (e.currentTarget?.files?.length > 0) {
                      setFieldValue('file', e.currentTarget.files[0]);
                      const base64 = await resizeFile(e.currentTarget.files[0], 300, 300, 200, 200);
                      setUser({...userData, avatar: base64});
                    }
                  }}
                />
              </div>

              <Form>
                <FormField errors={errors} handleChange={handleChange} id="userUsername" labeled title="Username" touched={touched} />
                
                <FormField errors={errors} handleChange={handleChange} id="userEmail" labeled title="E-mail" touched={touched} />

                <FormField errors={errors} handleChange={handleChange} id="userPhoneNumber" labeled title="Phone Number" touched={touched} />

                <button ref={ref} style={{display: 'none'}} type="submit">
                  <span>Save</span>
                </button>
              </Form>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ProfileEditSubmenu;
