module.exports = {
  experiments_create: {
    includeAssistant: false,
    includeHeader: true,
    path: '/spaces/create',
    regex: /^\/spaces\/create(?!\/)/,
    title: 'Creation Mode',
  },
  experiments_edit: {
    includeAssistant: true,
    includeHeader: true,
    path: '/spaces/:experimentId/edit',
    regex: /^\/spaces\/[\w-_.]{24}\/edit(?!\/)/,
    title: 'Edit Mode',
  },
  experiments_show: {
    includeAssistant: true,
    includeHeader: false,
    path: '/spaces/:experimentId',
    regex: /^\/spaces\/[\w-_.]{24}(?!\/)/,
    title: 'Preview Mode',
  },
  home: {
    includeAssistant: false,
    includeHeader: true,
    path: '/',
    regex: /^\/$|^$/,
    title: 'Home',
  },
  feed: {
    includeAssistant: false,
    includeHeader: true,
    path: '/feed',
    regex: /^\/feed(?!\/)/,
    title: 'Feed',
  },
  dashboard: {
    includeAssistant: false,
    includeHeader: true,
    path: '/dashboard',
    regex: /^\/dashboard(?!\/)/,
    title: 'Dashboard',
  },
  legal_privacyPolicy: {
    includeAssistant: false,
    includeHeader: true,
    path: '/privacy-policy',
    regex: /^\/privacy-policy(?!\/)/,
    title: 'Privacy Policy',
  },
  legal_termsOfUse: {
    includeAssistant: false,
    includeHeader: true,
    path: 'terms-of-use',
    regex: /^\/terms-of-use(?!\/)/,
    title: 'Terms of Use',
  },
  users_signIn: {
    includeAssistant: false,
    includeHeader: true,
    path: '/sign-in',
    regex: /^\/sign-in(?!\/)/,
    title: 'Sign In',
  },
  users_signUp: {
    includeAssistant: false,
    includeHeader: true,
    path: '/sign-up',
    regex: /^\/sign-up(?!\/)/,
    title: 'Sign Up',
  },
};
