import styles from 'assets/styles/modules/Modal.module.scss';
import { motion } from 'framer-motion';

const ModalLevel = ({ active, columns = 1, children }) => {
  const anim = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <>
      {active && (
        <motion.div
          className={`${styles.level} ${
            columns === 2 ? styles.twoColumns : null
          }`}
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={anim}
        >
          {children}
        </motion.div>
      )}
    </>
  );
};

export default ModalLevel;
