import { useEffect } from 'react';

const useSelectTemplate = (content, currentLayout) => {
  useEffect(() => {
    // Update the selected PageContent if the user swiped to change layout
    if (
      content?.rowId &&
      !content?.rowId.includes('placeholder') &&
      currentLayout !== 0
    ) {
      async function updateSelection(optionId) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/v1/templates/${optionId}/select`,
          {
            body: JSON.stringify({ variationNumber: currentLayout }),
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
          },
        );
      }

      updateSelection(content.rowId);
    }
  }, [content, currentLayout]);
};

export default useSelectTemplate;
