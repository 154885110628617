const BookmarkFilled = () => {
  return (
    <svg viewBox="0 0 12 15.51">
      <path
        d="m10.57,15.2c.47.59,1.43.26,1.43-.5V.8c0-.44-.36-.8-.8-.8H.8c-.44,0-.8.36-.8.8v13.91c0,.76.96,1.09,1.43.5l4.57-5.76,4.57,5.76Z"
        fill="#2f2f2f"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default BookmarkFilled;
