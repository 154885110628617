const Share = () => {
  return (
    <svg viewBox='0 0 13.5 13.64'>
      <path
        d='m0,12.89c0,.41.34.75.75.75h12c.41,0,.75-.34.75-.75v-3.25c0-.41-.34-.75-.75-.75s-.75.34-.75.75v2.5H1.5v-2.5c0-.41-.34-.75-.75-.75s-.75.34-.75.75v3.25Zm7.5-3.75V2.46l2.43,2.43c.29.29.77.29,1.06,0,.29-.29.29-.77,0-1.06L7.46.29c-.39-.39-1.02-.39-1.41,0l-3.54,3.54c-.29.29-.29.77,0,1.06.29.29.77.29,1.06,0l2.43-2.43v6.68c0,.41.34.75.75.75s.75-.34.75-.75Z'
        fill='#1c1c1c'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Share;
