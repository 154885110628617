import React from 'react';
import { Field } from 'formik';

const FormField = ({ customChangeCallback, errors, handleChange, id, labeled, placeholder, title, touched, type = 'text', }) => {
  const onChangeHandler = (e) => {
    if (!handleChange) return;

    handleChange(e);
    customChangeCallback !== undefined && typeof customChangeCallback === 'function' && customChangeCallback(e.target.value);
  }

  return (
    <div className='formRow'>
      <label className={`formRow_label ${labeled ? '' : 'visually-hidden'}`} htmlFor={id}>
        {title}
      </label>

      <Field id={id} name={id} onChange={ onChangeHandler } placeholder={placeholder ? placeholder : title} type={type} />
      
      {errors[id] && touched[id] &&
        <div className='sp1 formRow_message'>{errors[id]}</div>
      }
    </div>
  );
};

export default FormField;
