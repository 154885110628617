import { useEffect, useRef, useState } from 'react';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import { Form, Formik } from 'formik';
import { SpaceImagesSchema } from 'schemas';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import Modal from '../Modal';
import ModalLevel from '../ModalLevel';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'state/store';
import { resizeFile } from 'utilities/resizeFile';
import FormEditImageMultiple from 'components/common/Forms/FormEditImageMultiple';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ModalBody from '../ModalBody';

const ImagesModal = ({
  active,
  setActive,
  content,
  setTempImages,
  tempImages,
}) => {
  const refImage = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState('');
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel('');
    setTempImages(['', '', '', ]);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempImages(['', '', '', ]);
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === 'Images') {
      submitImageForm();
    }
    setTimeout(() => {
      setActiveLevel('');
      setActive(false);
    }, 1000);
  };

  const handleRegenerateImage = async (imageUrl) => {
    const urlSplitBySlash = imageUrl.split('/');
    const imageId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    const response = await sections.regenerateImage( userData.userId, content.rowId, imageId);
    const newTempImageUrls = content.images.map((originalImageUrl) => originalImageUrl === imageUrl ? response.outputUrl : '');
    const finalizedTempImageUrls = newTempImageUrls.map((ti, i) => ti || tempImages[i]);
    setTempImages(finalizedTempImageUrls);
  }

  const submitImageForm = () => {
    if (refImage.current) {
      refImage.current.click();
    }
  };

  useEffect(() => {
    if (activeLevel === '') {
      setHeaderTitle('Images');
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            title={headerTitle}
            handleHeadingClose={handleHeadingClose}
            handleHeadingSubmit={handleHeadingSubmit}
            handleBack={handleBack}
            levelIsActive={activeLevel !== ''}
          />
          <ModalBody activeLevel={activeLevel}>
            <ModalOptions active={activeLevel === ''}>
              <ModalOptionsLine
                title={'Images'}
                handleClick={() => activateSubLevel('Images')}
              />
            </ModalOptions>
            {/* Image Level */}
            <ModalLevel active={activeLevel === 'Images'} columns={2}>
              <Formik initialValues={{ files: [null, null, null, null, null, ], }} validationSchema={SpaceImagesSchema}
                onSubmit={async (values) => {
                  const items = [];
                  const types = [];

                  for (const item of values.files) {
                    const base64 = await resizeFile(item);
                    items.push(base64);
                    types.push(item.type);
                  }

                  if (items.length > 0 && types.length > 0) {
                    const response = await sections.updateImages(
                      userData.userId,
                      content.rowId, // sectionId
                      items, // image base64 format array
                      types, // contentTypes
                    );

                    if (response?.success) setActive(false);
                  } else {
                    console.log('Images array is empty');
                  }
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    {content?.images?.map((image, i) => (
                      <FormEditImageMultiple
                        errors={ errors }
                        img={ tempImages[i] || image }
                        key={ `galleryModal__${i}` }
                        multiple={ true }
                        generate={ handleRegenerateImage }
                        rowId={ content.rowId }
                        setFieldValue={ setFieldValue }
                        setTempImages={ setTempImages }
                        slot={ i }
                        tempImages={ tempImages }
                        touched={ touched }
                      />
                    ))}
                    <button ref={ refImage } style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default ImagesModal;
