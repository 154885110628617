import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/PlaceholderSection.module.scss';

const PlaceholderSection = ({ }) => {
  return (
    <>
      <section className={globalSectionStyles.container} draggable='false' style={{ userSelect: 'none' }}>
        <div className={`${globalSectionStyles.contentEditView} ${styles.content}`} data-layout={ 1 }>
          <div>
            <p>
              Your section is being generated. Give yourself a moment to breathe.
            </p>
          </div>

          <div className={styles.titleContainer}>
            <h1>Breathe.</h1>
          </div>
        </div>
      </section>

      <div className={globalSectionStyles.divider}></div>
    </>
  );
};

export default PlaceholderSection;
