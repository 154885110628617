import Avatar from '../Avatar';
import CircleIcon from 'assets/icons/CircleIcon';
import styles from 'assets/styles/modules/ProjectItemHeader.module.scss';
import ArrowRightAngle from 'assets/icons/ArrowRightAngle';
import { useNavigate } from 'react-router-dom';
import useCustomer from 'lib/hooks/useCustomer';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import useAnimate from 'lib/hooks/useAnimate';

const ProjectItemHeader = ({ experiment }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const date = experiment?.createdAt
    ? moment(experiment.createdAt).format('LL')
    : null;

  useAnimate([ref.current], 200, styles.visible);

  return (
    <div className={styles.container}>
      {experiment && (
        <>
          <Avatar
            name={experiment.creator?.username}
            date={date}
            img={experiment.creator?.avatar}
            small
          />
          <div
            className={styles.action}
            onClick={() => navigate(`/spaces/${experiment._id}`)}
            ref={ref}
          >
            <CircleIcon iconWidth={'w-8'}>
              <ArrowRightAngle />
            </CircleIcon>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectItemHeader;
