import styles from 'assets/styles/modules/DashboardEmptyCard.module.scss';
import ArrowRightAngleLg from 'assets/icons/ArrowRightAngleLg';
import PlusLg from 'assets/icons/PlusLg';

const DashboardEmptyCard = ({handleClick, title, text, iconName, iconWidth}) => {
  const printIcon = (iconName) => {
    if (iconName == 'Arrow') {
      return <ArrowRightAngleLg />;
    } else if (iconName == 'Plus') {
      return <PlusLg />;
    }
  };
  return (
    <div className={`${styles.container} shadow-md`} onClick={ handleClick }>
      <div className={`${styles.text} sp2 font-semibold`}>{ text }</div>
      <div className={`${styles.title} font-semibold`}>{ title }</div>
      {iconName && (
        <div className={`${styles.icon} ${iconWidth ? iconWidth : 'w-32'}`}>
          { printIcon(iconName) }
        </div>
      )}
    </div>
  );
};

export default DashboardEmptyCard;
