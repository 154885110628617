import styles from 'assets/styles/modules/Modal.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

const ModalOptions = ({ active, children }) => {
  const anim = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.3,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <AnimatePresence>
      {active && (
        <motion.div
          initial='hidden'
          animate='visible'
          exit='hidden'
          variants={anim}
          className={styles.options}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalOptions;
