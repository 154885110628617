const SectionButton = () => {
  return (
    <svg width='43' height='10' viewBox='0 0 43 10'>
      <rect
        x='.5'
        y='.5'
        width='42'
        height='9'
        rx='1.5'
        ry='1.5'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SectionButton;
