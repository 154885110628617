import styles from 'assets/styles/modules/Modal.module.scss';
import { AnimateChangeInHeight } from '../AnimateChangeInHeight';

const ModalBody = ({ activeLevel, children }) => {
  return (
    <AnimateChangeInHeight>
      <div className={styles.body}>{children}</div>
    </AnimateChangeInHeight>
  );
};

export default ModalBody;
