const Ellipsis = () => {
  return (
    <svg viewBox="0 0 3 16">
      <path
        d="m0,1.5c0,.83.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5S0,.67,0,1.5Zm0,6.5c0,.83.67,1.5,1.5,1.5s1.5-.67,1.5-1.5-.67-1.5-1.5-1.5-1.5.67-1.5,1.5Zm1.5,8c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5,1.5.67,1.5,1.5-.67,1.5-1.5,1.5Z"
        fill="#262626"
      />
    </svg>
  );
};

export default Ellipsis;
