import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from 'assets/styles/modules/ProjectIframe.module.scss';

const ProjectIframe = ({ experiment, rowIndex }) => {
  const navigate = useNavigate();

  function previewSpace() {
    navigate(`/spaces/${experiment._id}`);
  }

  return (
    <>
      <iframe
        className={`page-option-preview projects-view`}
        height='100%'
        id={experiment.previewId}
        loading={[1, 2].includes(rowIndex) ? 'eager' : 'lazy'}
        src={`${process.env.REACT_APP_API_URL}/v1/templates/${experiment.previewId}`}
        title={`${experiment._id}`}
        width='100%'
      />

      <div className={ styles.clickableOverlay } onClick={ previewSpace }></div>
    </>
  );
};

export default ProjectIframe;
