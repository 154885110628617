import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import TemporaryAlert from '../TemporaryAlert';
import NavBar from './NavBar';
import Menu from './Menu';
import styles from 'assets/styles/modules/Header.module.scss';

function Header({ pageName, utilities }) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState('');
  const location = useLocation();
  const [showHeader, setShowHeader] = useState(false);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
    setSelectedSubMenu('');
  };

  useEffect(() => {
    handleRouteChange();
  }, [location]);

  useEffect(() => {
    const mainElement = document.querySelector('.main');

    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
      if (mainElement) {
        mainElement.style.overflow = 'hidden';
      }
    } else {
      document.body.style.overflow = '';
      if (mainElement) {
        mainElement.style.overflow = '';
      }
    }
  }, [menuIsOpen]);

  function handleRouteChange() {
    const routerInfo =
      Object.values(utilities.routes).find((route) =>
        route.regex.test(location.pathname),
      ) || {};
    setShowHeader(routerInfo.includeHeader || false);
  }

  if (!showHeader) return null;

  return (
    <>
      <Menu
        menuIsOpen={menuIsOpen}
        selectedSubMenu={selectedSubMenu}
        setSelectedSubMenu={setSelectedSubMenu}
      />
      <div className={styles.header}>
        <NavBar menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
        <TemporaryAlert />
      </div>
    </>
  );
}

export default Header;
