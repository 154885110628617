import React from 'react';
import styles from 'assets/styles/modules/CircleIcon.module.scss';

const variantStyles = (variant) => {
  switch (variant) {
    case 'filled':
      return styles.filled;
    case 'filledInverted':
      return styles.filledInverted;
    case 'outlineInverted':
      return styles.outlineInverted;
    default:
      return styles.filled;
  }
};

const CircleIcon = ({ children, variant, iconWidth }) => {
  return (
    <div className={`${styles.container} ${variantStyles(variant)}`}>
      <div className={`${iconWidth ? iconWidth : ''} ${styles.icon}`}>
        {children}
      </div>
    </div>
  );
};

export default CircleIcon;
