import React from 'react';
import styles from 'assets/styles/modules/MainNavigationFooter.module.scss';

const MainNavigationFooter = () => {
  return (
    <div className={`${styles.container} sp2`}>
      If you have any questions please refer to our <br />
      <a href="/terms-of-use">Terms of Use</a> and{' '}
      <a href="/privacy-policy">Privacy Policy</a>.
    </div>
  );
};

export default MainNavigationFooter;
