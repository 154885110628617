import FormFieldInfo from '../Forms/FormFieldInfo';
import SubmenuCard from './SubmenuCard';
import SubmenuHeading from './SubmenuHeading';
import styles from 'assets/styles/modules/Submenu.module.scss';

const PlanSubmenu = ({selectedSubMenu, closeSubmenu, setSelectedSubMenu}) => {
  return (
    <div
      className={`${styles.container} ${
        selectedSubMenu === 'plan' || selectedSubMenu === 'creditsInfo'
          ? styles.visible
          : ''
      } custom-scrollbar`}
    >
      <SubmenuHeading
        title="My Plan"
        leftCtaTitle="Back"
        leftCtaAction={closeSubmenu}
      />
      <div className={styles.main}>
        <FormFieldInfo title="Current Plan" text="FREE" />
        <div onClick={() => setSelectedSubMenu('creditsInfo')}>
          <FormFieldInfo title="Credits" text="100" icon />
        </div>
        <div className={styles.cards}>
          <SubmenuCard
            title="Invite a Friend"
            subTitle="Receive 50 extra credits"
            iconName="Megaphone"
          >
            <li>You have 2/2 invitations left.</li>
          </SubmenuCard>
          <SubmenuCard
            title="Upgrade to Plus"
            subTitle="USD $9/month"
            iconName="Stars"
          >
            <li>Unlimited credits</li>
            <li>Access special templates and more</li>
          </SubmenuCard>
        </div>
      </div>
      <p className={`${styles.footer} sp1`}>
        <a href="/terms-of-use">Manage my subscription</a>
        <br />
        <a href="/privacy-policy">I need help with a billing issue</a>.
      </p>
    </div>
  );
};

export default PlanSubmenu;
