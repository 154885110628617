import { Form, Formik, } from 'formik';
import { AnimatePresence, } from 'framer-motion';
import { useEffect, useRef, useState, } from 'react';
import { SpaceHomeTitleSchema, SpaceHomeImageSchema, SpaceTextSchema, } from 'schemas';
import { useStore, } from 'state/store';

import { resizeFile, } from 'utilities/resizeFile';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import useScrollBlock from 'lib/hooks/useScrollBlock';

import FormEditImage from '../../Forms/FormEditImage';
import FormField from '../../Forms/FormField';
import FormTextarea from 'components/common/Forms/FormTextarea';
import GenerateButton from 'components/common/Buttons/GenerateButton';
import Modal from '../Modal';
import ModalBody from '../ModalBody';
import ModalHeading from '../ModalHeading';
import ModalLevel from '../ModalLevel';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';

const AboutModal = ({
  active,
  setActive,
  content,
  setTempImage,
  setTempTitle,
  setTempText,
  tempImage,
  tempText,
  tempTitle,
}) => {
  const refTitle = useRef(null);
  const refImage = useRef(null);
  const refText = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState('');
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel('');
    setTempImage(null);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setTempImage(null);
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === 'About Title') {
      submitTitleForm();
    } else if (activeLevel === 'About Image') {
      submitImageForm();
    } else if (activeLevel === 'About Text') {
      submitTextForm();
    }
    setTimeout(() => {
      setActiveLevel('');
      setActive(false);
    }, 1000);
  };

  const handleImageUpload = async (values) => {
    const base64 = await resizeFile(values.file);
    const response = await sections.updateImages(userData.userId, content.rowId, [base64, ], values.file.type);
    if (response?.success) setActive(false);
  }

  const handleRegenerateImage = async (imageUrl) => {
    const urlSplitBySlash = imageUrl.split('/');
    const imageId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    const response = await sections.regenerateImage( userData.userId, content.rowId, imageId);
    setTempImage(response.outputUrl);
  }

  const handleRegenerateTextBody = async (textContent) => {
    const response = await sections.regenerateText( userData.userId, content.rowId, textContent);
    setTempText(response.result);
  }

  const handleRegenerateTextTitle = async (textContent) => {
    const response = await sections.regenerateText(userData.userId, content.rowId, textContent);
    setTempTitle(response.result);
  }

  const submitTitleForm = () => {
    if (refTitle.current) refTitle.current.click();
  };

  const submitImageForm = () => {
    if (refImage.current) refImage.current.click();
  };

  const submitTextForm = () => {
    if (refText.current) refText.current.click();
  };

  useEffect(() => {
    if (activeLevel === '') {
      setHeaderTitle('About');
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading handleBack={ handleBack } handleHeadingClose={ handleHeadingClose } handleHeadingSubmit={ handleHeadingSubmit } levelIsActive={ activeLevel  !== ''} title={ headerTitle } />
          <ModalBody activeLevel={activeLevel}>
            <ModalOptions active={activeLevel === ''}>
              <ModalOptionsLine handleClick={() => activateSubLevel('About Title')} title={'Title'} />

              <ModalOptionsLine handleClick={() => activateSubLevel('About Image')} title={'Image'} />

              <ModalOptionsLine handleClick={() => activateSubLevel('About Text')} title={'Text'} />
            </ModalOptions>

            {/* Title Level */}

            <ModalLevel active={activeLevel === 'About Title'} columns={1}>
              <div className='mb-32'>
                <Formik enableReinitialize={ true } initialTouched={ { title: true, } } initialValues={{ title: tempTitle !== null ? tempTitle : content?.texts[0], }} validationSchema={ SpaceHomeTitleSchema }
                  onSubmit={async (values) => {
                    const updatedTexts = content.texts;
                    updatedTexts[0] = values.title;
                    const response = await sections.updateTexts(
                      userData.userId,
                      content.rowId,
                      updatedTexts,
                    );
                    if (response?.success) setActive(false);
                  }}
                >
                  {({ errors, touched, handleChange, }) => (
                    <Form>
                      <FormField customChangeCallback={ setTempTitle } errors={ errors } handleChange={ handleChange } id='title' labeled='false' title='' touched={ touched } type='text' />
                      
                      <button ref={refTitle} style={{ display: 'none' }} type='submit'>
                        <span>Save</span>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </ModalLevel>

            {/* Image Level */}

            <ModalLevel active={activeLevel === 'About Image'} columns={2}>
              <Formik initialValues={{ file: null, }} onSubmit={async (values) => { await handleImageUpload(values); }} validationSchema={ SpaceHomeImageSchema }>
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <FormEditImage errors={errors} generate={ handleRegenerateImage } img={ tempImage || content.images[0] } rowId={ content.rowId } setFieldValue={ setFieldValue } setTempImage={setTempImage} touched={ touched } />
                    
                    <button ref={refImage} style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>

            {/* Text Level */}

            <ModalLevel active={activeLevel === 'About Text'} columns={1}>
              <Formik enableReinitialize={ true } initialTouched={ { text: true, } } initialValues={{ text: tempText !== null ? tempText : content?.texts[1], }} validationSchema={SpaceTextSchema}
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[1] = values.text;
                  const response = await sections.updateTexts(
                    userData.userId,
                    content.rowId,
                    updatedTexts,
                  );
                  if (response?.success) setActive(false);
                }}
              >
                {({ errors, touched, handleChange }) => (
                  <Form>
                    <FormTextarea customChangeCallback={ setTempText } errors={ errors } handleChange={ handleChange } id='text' labeled={ false } touched={ touched } />

                    <button ref={refText} style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>

              <GenerateButton action={ () => handleRegenerateTextBody(tempText || content.texts[1]) } />
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default AboutModal;
