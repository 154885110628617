import Megaphone from 'assets/icons/Megaphone';
import Stars from 'assets/icons/Stars';
import styles from 'assets/styles/modules/SubmenuCard.module.scss';

const SubmenuCard = ({title, subTitle, children, iconName}) => {
  const printIcon = (iconName) => {
    if (iconName == 'Stars') {
      return <Stars />;
    } else if (iconName == 'Megaphone') {
      return <Megaphone />;
    }
  };
  return (
    <div className={styles.card}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subTitle}</div>
      <ul className={`${styles.list} font-semibold`}>{children}</ul>
      {iconName && <div className={styles.icon}>{printIcon(iconName)}</div>}
    </div>
  );
};

export default SubmenuCard;
