import useExperiments from 'lib/hooks/useExperiments';

import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import Edit from 'assets/icons/Edit';
import Plus from 'assets/icons/Plus';

import { convertVideoToGif, } from 'utilities/convertVideoToGif';
import { resizeFile, } from 'utilities/resizeFile';
import { trimAndEllipsis, } from 'utilities/text';
import utilities from 'utilities/index';

function CreationFormQuestions({
  activeStep,
  description,
  goToStep,
  onFieldFocus,
  setDescription,
  setTitle,
  setUploadedImageUrl,
  title,
  uploadedImageUrl,
  user,
}) {
  const userExperiments = useExperiments(user._id);

  function mapTitle(customClass, title) {
    return title.split(' ').map((word, i) => {
      return (
        <div className="word" key={`word-${i}`}>
          {word.split('').map((char, i) => (
            <div className={`char ${customClass}`} key={`char-${i}`}>
              {char}
            </div>
          ))}
        </div>
      );
    });
  }

  function handleImageUpload() {
    document.getElementById('image-file-upload').click();
  }

  async function uploadImage(e) {
    const file = e?.currentTarget?.files?.[0];
    if (!file) return;

    let b64 = '';
    let fileName = '';

    if (file.type === 'video/mp4') {
      b64 = await convertVideoToGif(file);
      fileName = `${file.name.split('.')[0]}.gif`;
    } else {
      b64 = await resizeFile(file);
      fileName = file.name;
    }

    const res = await userExperiments.uploadImage(user._id, fileName, b64);
    setUploadedImageUrl(res.location);
  }

  return (
    <div className="formSteps" data-step={activeStep}>
      <div className="formSteps_slider">
        <div className={`step step--first ${activeStep - 1 === 0 ? 'step--prev' : ''} ${activeStep === 0 ? 'step--active' : ''}`} data-step="0">
          <div className="step_title title-0">
            <h2 className="st1" data-split>
              { mapTitle('title-0', user && user.username ? `Hello, ${user.username}` : 'Hello there.') }
            </h2>
          </div>
        </div>

        <div className={`step ${activeStep - 1 === 1 ? 'step--prev' : ''} ${activeStep === 1 ? 'step--active' : ''} ${activeStep + 1 === 1 ? 'step--next' : ''}`} data-step="1">
          <div className="step_title title-1">
            <h2 className="st2" data-split>
              {mapTitle('title-1', 'Tell me the name of your space')}
            </h2>
          </div>

          <div className={`step_content ${activeStep === 1 ? 'active' : ''}`}>
            <div className="formRow">
              <textarea data-focus data-autogrow onFocus={onFieldFocus} placeholder="Type here" rows="1"
                onChange={(e) => {
                  utilities.elements.setHeightToTrueSize(e.target);
                  setTitle(e.target.value);
                }}
              ></textarea>

              <div className="formRow_description">
                e.g. Flower Street, John & Co.
              </div>
            </div>
          </div>
        </div>

        <div
          className={`step ${activeStep - 1 === 2 ? 'step--prev' : ''} ${
            activeStep === 2 ? 'step--active' : ''
          } ${activeStep + 1 === 2 ? 'step--next' : ''}`}
          data-step="2"
        >
          <div className="step_title title-2">
            <h2 className="st2" data-split>
              {mapTitle('title-2', 'Could you give me a short description')}
            </h2>
          </div>

          <div className={`step_content ${activeStep === 2 ? 'active' : ''}`}>
            <div className="formRow">
              <textarea data-focus data-autogrow onFocus={ onFieldFocus } placeholder="Type here" rows="1"
                onChange={(e) => {
                  utilities.elements.setHeightToTrueSize(e.target);
                  setDescription(e.target.value);
                }}
              ></textarea>

              <div className="formRow_description">
                e.g. I am a young flower artist based in Cerebral Valley, San
                Francisco. I design unique arrangements for the local community.
              </div>
            </div>
          </div>
        </div>

        <div className={`step ${activeStep - 1 === 3 ? 'step--prev' : ''} ${activeStep === 3 ? 'step--active' : ''} ${activeStep + 1 === 3 ? 'step--next' : ''}`} data-step="3">
          <div className="step_title title-3">
            <h2 className="st2" data-split>
              {mapTitle('title-3', 'Would you like to add a cover image?')}
            </h2>
          </div>

          <div className="step_subtitle title-3">
            <h3 className="st3" data-split>
              {mapTitle('title-3', 'This is optional. You can always change it later or skip this step.')}
            </h3>
          </div>

          <div className={`step_content ${activeStep === 3 ? 'active' : ''}`}>
            <div className="upload-section">
              <span className="content file-bubble ">
                <div className="image-container shadow-sm" onClick={ handleImageUpload }>
                  {!uploadedImageUrl &&
                    <>
                      <Plus color='#002534' />

                      <input accept="image/*,video/mp4" id="image-file-upload" name="file" onChange={ uploadImage } style={{ display: 'none' }} type="file" />
                    </>
                  }
                  {!!uploadedImageUrl &&
                    <img src={ uploadedImageUrl } />
                  }
                </div>
              </span>
            </div>
          </div>
        </div>

        <div className={`step ${activeStep - 1 === 4 ? 'step--prev' : ''} ${activeStep === 4 ? 'step--active' : ''} ${activeStep + 1 === 4 ? 'step--next' : ''}`} data-step="4">
          <div className="step_title title-4">
            <h2 className="st1" data-split>
              {mapTitle('title-4', 'Your Space')}
            </h2>
          </div>

          <div className="summary-section">
            <div className="summary-bubble title-bubble">
              <span className="content">
                { trimAndEllipsis(title, 20) }
              </span>

              <span className="action-icon">
                <span className="flex-column centered" onClick={ () => goToStep(1) }>
                  <CircleIcon variant="filledInverted" iconWidth={'w-14'}>
                    <Edit />
                  </CircleIcon>
                </span>
              </span>
            </div>

            <div className="summary-bubble image-bubble">
              <span className="content" onClick={ () => uploadedImageUrl ?  null : handleImageUpload() }>
                
                  <div className="image-container shadow-sm">
                    {!uploadedImageUrl &&
                      <Plus color='#002534' />
                    }
                    
                    {!!uploadedImageUrl &&
                      <span>
                        <img src={ uploadedImageUrl } />
                      </span>
                    }
                  </div>
              </span>

              <span className="action-icon">
                <span className="flex-column centered" onClick={ () => setUploadedImageUrl('') }>
                  <CircleIcon variant="filledInverted" iconWidth={'w-14'}>
                    <Bin />
                  </CircleIcon>
                </span>
              </span>
            </div>

            <div className="summary-bubble">
              <span className="content">
                { trimAndEllipsis(description, 75) }
              </span>

              <span className="action-icon">
                <span className="flex-column centered" onClick={ () => goToStep(2) }>
                  <CircleIcon variant="filledInverted" iconWidth={'w-14'}>
                    <Edit />
                  </CircleIcon>
                </span>
              </span>
            </div>
          </div>

          <div className={`step_content ${activeStep === 4 ? 'active' : ''}`}>
          </div>
        </div>

        <div className={`step step--last ${activeStep - 1 === 5 ? 'step--prev' : ''} ${activeStep === 5 ? 'step--active' : ''} ${activeStep + 1 === 5 ? 'step--next' : ''}`} data-step="5">
          <div className={`step_content active ${activeStep === 5 ? 'active' : ''}`}>
            <div className="generationCircle" id="generation-circle">
              <div className="generationCircle_value" id="circle-progress-text">
                0%
              </div>

              <svg
                className="generationCircle_progress"
                version="1.1"
                viewBox="0 0 300 300"
                preserveAspectRatio="xMinYMin meet"
              >
                <circle
                  className="generationCircle_progress_bar"
                  id="circle-progress-svg"
                  r="149.5"
                  cx="150"
                  cy="150"
                  fill="transparent"
                  stroke="#2F2F2F"
                  strokeDasharray="939"
                  strokeDashoffset="939"
                  strokeWidth="1px"
                ></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreationFormQuestions;
