import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/FooterSection.module.scss';
import { motion } from 'framer-motion';
import { spring } from 'utilities/animations';

const FooterSection = ({ isShowPage, title }) => {
  return (
    <section className={globalSectionStyles.container}>
      <div className={ `${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}` } data-layout={ '1' }>
        <motion.div className={isShowPage ? styles.showPageText : styles.text} layout transition={spring}>
          <div>
            ©{' '}
            { title?.split(' ').map((word) => { return word[0]?.toUpperCase() + word.substring(1); }).join(' ') }
            { ' ' }
            { new Date().getFullYear() }
          </div>
          
          <div className={ styles.watermark }>
            Created with&nbsp;
            { isShowPage ?
                <a href="https://www.off.space" target="_blank">off.space</a>
              :
                <a>off.space</a>
            }
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default FooterSection;
