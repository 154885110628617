import SquareIcon from 'assets/icons/SquareIcon';
import styles from 'assets/styles/modules/DashboardStatusItem.module.scss';

const DashboardStatusItem = ({
  title,
  subtitle,
  children,
  iconWidth,
  section,
  activeSection,
  setActiveSection,
}) => {
  return (
    <div
      className={`${styles.container} sp2 text-center font-semibold`}
      onClick={() => setActiveSection(section)}
    >
      <div className={styles.title}>{title}</div>
      <SquareIcon
        iconWidth={iconWidth}
        variant={activeSection === section ? 'outlineInverted' : ''}
      >
        {children}
      </SquareIcon>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};

export default DashboardStatusItem;
