import HomeModal from 'components/common/Modal/Home/HomeModal';
import { useEffect, useState } from 'react';
import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/HomeSection.module.scss';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import EditButton from './ui/EditButton';
import { useAnimation, motion } from 'framer-motion';
import ArrowSwipe from 'assets/icons/ArrowSwipe';
import CircleIcon from 'assets/icons/CircleIcon';
import Plus from 'assets/icons/Plus';
import { spring } from 'utilities/animations';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';

const HomeSection = ({ content, isShowPage, openEdit }) => {
  const [active, setActive] = useState(false);
  const [currentLayout, setCurrentLayout] = useState(0);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  const [tempImage, setTempImage] = useState(null);
  const [tempTitle, setTempTitle] = useState(null);
  const ctrls = useAnimation();
  useSelectTemplate(content, currentLayout);

  const imageAnimation = {
    hidden: {
      scale: 1.1,
      //filter: 'blur(5px)',
      transition: spring,
    },
    visible: {
      scale: 1,
      //filter: 'blur(0)',
      transition: spring,
    },
  };

  const headingAnimation = {
    hidden: {
      opacity: 0,
      y: '100%',
      transition: {
        duration: 0.2,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    one: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    two: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    three: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  useEffect(() => {
    // Initial state will be "hidden"
    ctrls.start('hidden');

    // We must delay the layout change in order to allocate some time to perform the reveal animations
    const t1 = setTimeout(() => {
      setCurrentLayout(layout === 0 ? 1 : layout);
    }, 250);

    // We now proceed to animate the heading based on the current layout selection
    let t2;
    if (layout === 1) {
      t2 = setTimeout(() => {
        ctrls.start('one');
        ctrls.start('visible');
      }, 450);
    }
    if (layout === 2) {
      t2 = setTimeout(() => {
        ctrls.start('two');
        ctrls.start('visible');
      }, 450);
    }
    if (layout === 3) {
      t2 = setTimeout(() => {
        ctrls.start('three');
        ctrls.start('visible');
      }, 450);
    }

    return () => {
      // Timeouts cleanup
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, [layout]);

  return (
    <>
      <section { ...(isShowPage ? [] : swipeHandlers) } className={globalSectionStyles.container}  draggable='false' style={{ userSelect: 'none' }}>
        <HomeModal active={active} content={content} setActive={setActive} setTempImage={setTempImage} setTempTitle={setTempTitle} tempImage={tempImage} tempTitle={tempTitle} />

        { openEdit &&
          <EditButton openModal={setActive} position='bottomRightNoImage' />
        }

        <div className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`} data-layout={currentLayout}>
          <motion.div animate={ctrls} className={styles.background} initial='hidden' variants={imageAnimation}>
            <img alt={content.texts[0]} draggable='false' src={tempImage ? tempImage : content.images[0]} />
          </motion.div>

          <h2 className={styles.heading}>
            <div style={{ overflow: 'hidden' }}>
              <motion.div animate={ctrls} initial='hidden' variants={headingAnimation}>
                {tempTitle ? tempTitle : content.texts[0]}
              </motion.div>
            </div>
          </h2>
        </div>

        { openEdit &&
          <div className={styles.swipe}>
            <div className={styles.swipeIco}>
              <ArrowSwipe />
            </div>
          </div>
        }

        { openEdit &&
          <div className={globalSectionStyles.addSection} onClick={ () => openEdit({ status: true, afterPosition: content.position }) }>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        }
      </section>

      { openEdit &&
        <div className={globalSectionStyles.divider}></div>
      }
    </>
  );
};

export default HomeSection;
