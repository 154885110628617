import DashboardCard from './DashboardCard';
import styles from 'assets/styles/modules/DashboardBadgesCard.module.scss';
import image from 'assets/images/badge-100s.svg';

const DashboardBadgesCard = () => {
  return (
    <DashboardCard>
      <div className={styles.inner}>
        <div className={`${styles.header} sp2 font-semibold text-center`}>
          <div className={styles.heading}>TOKEN 100’s</div>
          <div>You are one of the first 100 members.</div>
        </div>
        <div className={styles.main}>
          <img src={image} alt="image" />
        </div>
        <div className={`${styles.status} ${styles.active} sp2 font-semibold`}>
          Active
        </div>
      </div>
    </DashboardCard>
  );
};

export default DashboardBadgesCard;
