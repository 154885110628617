import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';
import Rellax from 'rellax';

import styles from 'assets/styles/modules/ShowPage.module.scss';
import useExperimentEditData from 'lib/hooks/useExperimentEditData';
import useRequireAuthenticated from 'lib/hooks/useRequireAuthenticated';

import CircleIcon from 'assets/icons/CircleIcon';
import Close from 'assets/icons/Close';
import ProjectItemActions from 'components/common/Project/ProjectItemActions';

import AboutSection from './AboutSection';
import AnimateWhenVisible from 'components/common/AnimateWhenVisible';
import ContactSection from './ContactSection';
import CtaSection from './CtaSection';
import FooterSection from './FooterSection';
import GallerySection from './GallerySection';
import HomeSection from './HomeSection';
import ImagesSectionThree from './ImagesSectionThree';
import NavBarSection from './NavBarSection';
import PlaceholderSection from './PlaceholderSection';
import QuoteSection from './QuoteSection';
import ScoreSection from './ScoreSection';
import TextSection from './TextSection';

const Show = () => {
  const navigate = useNavigate();
  const { experimentId } = useParams();
  const experimentEditData = useExperimentEditData();
  const [footerTitle, setFooterTitle] = useState('');
  const [contents, setContents] = useState(null);
  const [rellaxInit, setRellaxInit] = useState(null);

  const initializeRellax = async () => {
    // if the space isn't loaded yet, don't init
    if (!contents) return;

    // it's broken on mobile -- don't let it initialize
    if (window.screen.width < 650) return;

    // don't load rellax multiple times
    if (rellaxInit) return;
    setRellaxInit(new Rellax('.rellax-element', { breakpoints: [10, 20, 30, ], center: true, speed: -0.5, wrapper: '#parallax-scroll-parent', }));
  }
  

  useEffect(() => { initializeRellax() }, [contents, ]);


  useEffect(() => {
    async function stubForAsync() {
      const pageContents = await experimentEditData.fetchPageContents(experimentId);
      const pageTitle = pageContents.filter((content) => content.sectionType === 'navBar')[0]?.texts[0];

      setContents(pageContents);
      setFooterTitle(pageTitle);
    }
    stubForAsync();
  }, [experimentId, ]);

  useRequireAuthenticated();

  const handleClick = () => {
    document?.referrer === '' ? navigate('/dashboard') : navigate(-1);
  };

  return (
      <div className={ styles.wrapper } id='parallax-scroll-parent'>
        <div className={styles.container}>
          {contents?.map((pageContent, i) => {
              return ({
                navBar: (
                    <NavBarSection content={pageContent} isShowPage={ true } />

                ),
                home: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.4}>
                    <HomeSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                about: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.9}>
                    <AboutSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                quote: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.9}>
                    <QuoteSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                gallery: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <GallerySection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                score: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <ScoreSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                text: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <TextSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                images: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <ImagesSectionThree content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                contact: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <ContactSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                button: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <CtaSection content={pageContent} isShowPage={ true } />
                  </AnimateWhenVisible>
                ),
                placeholder: (
                  <AnimateWhenVisible key={`placeholder${i}__${i}`} delay={0.9}>
                    <PlaceholderSection />
                  </AnimateWhenVisible>
                ),
              }[pageContent.sectionType]);
            }
          )}

          {contents?.length > 0 &&
            <>
              <FooterSection isShowPage={ true } title={footerTitle} />
            </>
          }
        </div>

        <div className={styles.actions}>
          <div className={styles.close} onClick={handleClick}>
            <CircleIcon iconWidth="w-9">
              <Close />
            </CircleIcon>
          </div>

          <ProjectItemActions experimentData={ { _id: experimentId, creator: { _id: null,  }, } } variant={ 'outlineInverted' } />
        </div>
      </div>
  );
};

export default Show;
