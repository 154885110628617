import { Form, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { SpaceHomeTitleSchema, SpaceHomeImageSchema } from 'schemas';
import { useStore } from 'state/store';

import { resizeFile } from 'utilities/resizeFile';
import useScrollBlock from 'lib/hooks/useScrollBlock';

import FormEditImage from '../../Forms/FormEditImage';
import FormField from '../../Forms/FormField';
import GenerateButton from 'components/common/Buttons/GenerateButton';
import Modal from '../Modal';
import ModalBody from '../ModalBody';
import ModalHeading from '../ModalHeading';
import ModalLevel from '../ModalLevel';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import useExperimentSections from 'lib/hooks/useExperimentSections';

const HomeModal = ({
  active,
  setActive,
  content,
  setTempImage,
  setTempTitle,
  tempTitle,
  tempImage,
}) => {
  const refTitle = useRef(null);
  const refImage = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(null);
  const [activeLevel, setActiveLevel] = useState('');
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);

  useScrollBlock(active);

  const activateSubLevel = (levelName) => {
    setActiveLevel(levelName);
  };

  const handleBack = () => {
    setActiveLevel('');
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setActive(false);
    setActiveLevel('');
    setTempTitle(null);
    setTempImage(null);
  };

  const handleHeadingSubmit = () => {
    if (activeLevel === 'Home Title') {
      // User is on sublevel, therefore we must send the form data to update the header
      submitForm();
    } else if (activeLevel === 'Home Image') {
      submitImageForm();
    }
    setTimeout(() => {
      setActiveLevel('');
      setActive(false);
    }, 1000);
  };

  const handleImageUpload = async (values) => {
    const base64 = await resizeFile(values.file);
    const response = await sections.updateImages(userData.userId, content.rowId, [base64, ], values.file.type);
    if (response?.success) setActive(false);
  }

  const handleRegenerateImage = async (imageUrl) => {
    const urlSplitBySlash = imageUrl.split('/');
    const imageId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    const response = await sections.regenerateImage( userData.userId, content.rowId, imageId);
    setTempImage(response.outputUrl);
  }

  const handleRegenerateText = async (textUrl) => {
    const urlSplitBySlash = textUrl.split('/');
    const textId = urlSplitBySlash[urlSplitBySlash.length - 1].split('.')[0];

    const response = await sections.regenerateText( userData.userId, content.rowId, textId);
    setTempTitle(response.result);
  }

  const submitImageForm = () => {
    if (refImage.current) refImage.current.click();
  };

  const submitForm = () => {
    if (refTitle.current) refTitle.current.click();
  };

  useEffect(() => {
    if (activeLevel === '') {
      setHeaderTitle('Home');
    } else {
      setHeaderTitle(activeLevel);
    }
  }, [activeLevel]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            title={headerTitle}
            handleHeadingClose={handleHeadingClose}
            handleHeadingSubmit={handleHeadingSubmit}
            handleBack={handleBack}
            levelIsActive={activeLevel !== ''}
          />
          <ModalBody activeLevel={activeLevel}>
            <ModalOptions active={activeLevel === ''}>
              <ModalOptionsLine handleClick={ () => activateSubLevel('Home Title') } title={'Title'} />

              <ModalOptionsLine handleClick={() => activateSubLevel('Home Image')} title={'Image'} />
            </ModalOptions>

            {/* Title Level */}
            <ModalLevel active={activeLevel === 'Home Title'} columns={1}>
              <div className='mb-32'>
                <Formik enableReinitialize={ true } initialTouched={ { title: true, } } initialValues={{ title: tempTitle !== null ? tempTitle : content?.texts[0], }} validationSchema={SpaceHomeTitleSchema}
                  onSubmit={async (values) => {
                    const updatedTexts = content.texts;
                    updatedTexts[0] = values.title;
                    const response = await sections.updateTexts(userData.userId, content.rowId, updatedTexts);
                    if (response?.success) setActive(false);
                  }}
                >
                  {({ errors, touched, handleChange }) => (
                    <Form>
                      <FormField customChangeCallback={ setTempTitle } errors={ errors } handleChange={ handleChange } id='title' labeled='false' title='' touched={ touched } type='text' />

                      <button ref={ refTitle } style={{ display: 'none' }} type='submit'>
                        <span>Save</span>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </ModalLevel>

            {/* Image Level */}
            <ModalLevel active={activeLevel === 'Home Image'} columns={2}>
              <Formik initialValues={{ file: null, }} onSubmit={async (values) => { await handleImageUpload(values); }} validationSchema={SpaceHomeImageSchema}>
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <FormEditImage errors={ errors } generate={ handleRegenerateImage } img={ tempImage || content.images[0] } rowId={ content.rowId } setFieldValue={ setFieldValue } setTempImage={ setTempImage } touched={ touched } />

                    <button ref={refImage} style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default HomeModal;
