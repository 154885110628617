import { useNavigate } from 'react-router-dom';
import useCustomer from 'lib/hooks/useCustomer';
import useRequireUnauthenticated from 'lib/hooks/useRequireUnauthenticated';
import { Formik, Form } from 'formik';
import { CreateUserSchema } from 'schemas';
import FormField from 'components/common/Forms/FormField';

function RegistrationPage() {
  // User must be Unauthenticated to view this page
  useRequireUnauthenticated();

  const navigate = useNavigate();
  const user = useCustomer();

  return (
    <Formik
      initialValues={{
        inviteCode: '',
        password: '',
        phoneNumber: '',
        username: '',
      }}
      validationSchema={CreateUserSchema}
      onSubmit={async (values) => {
        const response = await user.createUser(values);
        if (response?.success) {
          navigate(`/spaces/create`);
        }
      }}
    >
      {({ errors, touched, handleChange }) => (
        <Form>
          <section className='main'>
            <div className='page_content p-signup'>
              <h3 className='st2'>Sign Up</h3>

              <p className='sp1'>
                You will use your phone number and password to log into your
                Paralllel account and save your work.
              </p>

              <div className='form'>
                <div className='formRow'>
                  <FormField
                    id='inviteCode'
                    title='Invite Code'
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
                <div className='formRow'>
                  <FormField
                    id='phoneNumber'
                    title='Phone Number'
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
                <div className='formRow'>
                  <FormField
                    id='username'
                    title='Username'
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
                <div className='formRow'>
                  <FormField
                    id='password'
                    title='Password'
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                  />
                </div>
              </div>

              <p className='sp3'>
                You agree to receive automated text messages from us, even if
                your mobile number is listed on any do-not-call registry. You
                also agree to our Terms of Use and acknowledge our Privacy
                Policy. Message frequency varies. <br />
                Message and data rates may apply. Text STOP to cancel.
              </p>
            </div>

            <div className='page_actions'>
              <button
                className='button button--filled button--sized'
                type='submit'
              >
                <span>Send</span>
              </button>
            </div>
          </section>
        </Form>
      )}
    </Formik>
  );
}

export default RegistrationPage;
