import { useEffect, useState } from 'react';
import { useStore } from 'state/store';

const useRequireUnauthenticated = () => {
  const user = useStore((state) => state.user);

  const isSignedIn = () => {
    return user.userId !== null && user.userId !== undefined;
  };

  /* Upon mount, check to see if user is signed in */
  useEffect(() => {
    if (isSignedIn()) {
      localStorage.setItem('message-for-user', 'You are already signed in.');
      window.location.assign('/');
    }
  }, []);

  return;
};

export default useRequireUnauthenticated;
