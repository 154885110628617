import React from 'react';
import { Field } from 'formik';
import CheckCircle from 'assets/icons/CheckCircle';

const FormTextarea = ({
  id,
  title,
  errors,
  touched,
  labeled,
  handleChange,
  customChangeCallback,
}) => {
  return (
    <div className='formRow formRow--ai'>
      <label
        htmlFor={id}
        className={`formRow_label ${labeled ? '' : 'visually-hidden'}`}
      >
        {title}
      </label>
      <Field
        id={id}
        name={id}
        placeholder={title}
        component='textarea'
        rows='10'
        onChange={
          handleChange
            ? (e) => {
                handleChange(e);
                customChangeCallback !== undefined &&
                  typeof customChangeCallback === 'function' &&
                  customChangeCallback(e.target.value);
              }
            : null
        }
      />
      {errors[id] && touched[id] ? (
        <div className='sp1 formRow_message'>{errors[id]}</div>
      ) : null}
      <span className='icon shadow-md'>
        <CheckCircle />
      </span>
    </div>
  );
};

export default FormTextarea;
