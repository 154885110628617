const ArrowRightAngleLg = () => {
  return (
    <svg viewBox="0 0 24.31 24.31">
      <path
        d="m23.31,1v20"
        fill="none"
        stroke="#828282"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="m23.31,1H3.31"
        fill="none"
        stroke="#828282"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="m23,1.31L1,23.31"
        fill="none"
        stroke="#828282"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default ArrowRightAngleLg;
