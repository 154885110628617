import React from 'react';

const UtilityButton = ({action, title, warning}) => {
  return (
    <button
      className={`button button--white ${warning ? 'button--warning' : ''}`}
      onClick={action}
    >
      {title}
    </button>
  );
};

export default UtilityButton;
