const Heart = ({color}) => {
  return (
    <svg viewBox="0 0 13.07 11.23">
      <path
        d="m12.04,1.01c-1.38-1.34-3.62-1.34-5,0l-.5.49-.5-.49C4.65-.34,2.42-.34,1.04,1.01-.35,2.35-.35,4.53,1.04,5.88l5.5,5.35h0l5.5-5.35c1.38-1.34,1.38-3.52,0-4.87Zm-1,3.89l-4.5,4.38L2.04,4.9c-.83-.8-.83-2.11,0-2.91s2.17-.8,2.99,0l.5.49c.27.26.63.4,1,.4s.74-.15,1-.4l.5-.49c.83-.8,2.17-.8,2.99,0,.83.8.83,2.11,0,2.91Z"
        fill={color ? color : '#2f2f2f'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Heart;
