const Stars = () => {
  return (
    <svg viewBox='0 0 15.93 20.81'>
      <path
        d='m5.56.3c-.08-.18-.27-.3-.47-.3s-.38.12-.47.3l-1.24,2.68-2.93.35c-.2.02-.37.16-.43.35s0,.4.14.54l2.17,2.01-.58,2.9c-.04.2.04.4.2.52.16.12.38.13.55.03l2.58-1.44,2.58,1.44c.18.1.39.09.55-.03.16-.12.24-.32.2-.52l-.58-2.9,2.17-2.01c.15-.14.2-.35.14-.54-.06-.19-.23-.33-.43-.35l-2.93-.35L5.56.3Zm7.04,13.7c-.06-.13-.19-.22-.34-.22s-.28.08-.34.22l-.89,1.93-2.11.25c-.14.02-.26.12-.31.25s0,.29.1.39l1.56,1.45-.41,2.09c-.03.14.03.29.15.37.12.09.27.09.4.02l1.86-1.04,1.86,1.04c.13.07.28.06.4-.02.12-.09.17-.23.15-.37l-.41-2.09,1.56-1.45c.11-.1.15-.25.1-.39-.04-.14-.17-.24-.31-.25l-2.11-.25-.89-1.93Z'
        fill='#eaeaea'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Stars;
