import styles from 'assets/styles/modules/Modal.module.scss';
import { useEffect, useRef, useState } from 'react';
import ModalHeading from '../ModalHeading';
import ModalOptions from '../ModalOptions';
import ModalOptionsLine from '../ModalOptionsLine';
import FormField from '../../Forms/FormField';
import FormImageImport from '../../Forms/FormImageImport';
import { Form, Formik } from 'formik';
import { SpaceHeaderSchema } from 'schemas';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import Modal from '../Modal';
import ModalLevel from '../ModalLevel';
import FormImportFileButton from 'components/common/Forms/FormImportFileButton';
import GenerateButton from 'components/common/Buttons/GenerateButton';
import { AnimatePresence } from 'framer-motion';
import { useStore } from 'state/store';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import { useParams } from 'react-router-dom';
import useExperiments from 'lib/hooks/useExperiments';
import ModalBody from '../ModalBody';

const HeaderModal = ({
  active,
  setActive,
  title,
  columns,
  content,
  setTempTitle,
}) => {
  const { experimentId } = useParams();
  const ref = useRef(null);
  const [headerTitle, setHeaderTitle] = useState(title);
  const [levelIsActive, setLevelIsActive] = useState(false);
  const sections = useExperimentSections();
  const userData = useStore((state) => state.user);
  const experiment = useExperiments(userData.userId);

  useScrollBlock(active);

  const handleBack = () => {
    setLevelIsActive(false);
  };

  const activateSubLevel = () => {
    setLevelIsActive(true);
  };

  const handleHeadingClose = () => {
    // Just close the modal
    setTempTitle(null);
    setActive(false);
  };

  const handleHeadingSubmit = () => {
    if (levelIsActive) {
      // User is on sublevel, therefore we must send the form data to update the header
      submitForm();
      {
        /* ADD LOADING STATE */
      }
      setTimeout(() => {
        setLevelIsActive(false);
        setActive(false);
      }, 1000);
    } else {
      // Just close the modal
      setActive(false);
    }
  };

  const submitForm = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  useEffect(() => {
    if (!levelIsActive) {
      setHeaderTitle('Header');
    } else {
      setHeaderTitle('Name');
    }
  }, [levelIsActive]);

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={handleHeadingClose}>
          <ModalHeading
            title={headerTitle}
            handleBack={handleBack}
            handleHeadingClose={handleHeadingClose}
            handleHeadingSubmit={handleHeadingSubmit}
            levelIsActive={levelIsActive}
          />
          
          <ModalBody activeLevel={levelIsActive}>
            <ModalOptions active={!levelIsActive}>
              <ModalOptionsLine handleClick={ activateSubLevel } title={ 'Name' } />
            </ModalOptions>

            <ModalLevel active={levelIsActive} columns={columns}>
              <Formik
                initialValues={{
                  name: content?.texts[0] || '',
                  //file: null,
                }}
                validationSchema={SpaceHeaderSchema}
                onSubmit={async (values) => {
                  const updatedTexts = content.texts;
                  updatedTexts[0] = values.name;
                  const response = await sections.updateTexts(
                    userData.userId,
                    content.rowId,
                    updatedTexts,
                  );
                  const responseExperiment = await experiment.updateExperiment({
                    _id: experimentId,
                    title: values.name,
                  });
                  if (response?.success && responseExperiment?.success) {
                    setActive(false);
                  }
                }}
              >
                {({ errors, touched, handleChange }) => (
                  <Form>
                    <FormField customChangeCallback={ setTempTitle } errors={ errors } handleChange={ handleChange } id='name' labeled='true' touched={ touched } type='text' />

                    {/* <div className='mt-16'>
                      <div className='mb-8'>
                        <FormImageImport errors={ errors } id='file' labeled='true' title='Space Logo' touched={ touched } />
                      </div>

                      <div className={styles.twoCols}>
                        <FormImportFileButton />

                        <GenerateButton square />
                      </div>
                    </div> */}

                    <button ref={ref} style={{ display: 'none' }} type='submit'>
                      <span>Save</span>
                    </button>
                  </Form>
                )}
              </Formik>
            </ModalLevel>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default HeaderModal;
