import SubmenuHeading from './SubmenuHeading';
import styles from 'assets/styles/modules/Submenu.module.scss';

const CreditsInfoSubmenu = ({selectedSubMenu, setSelectedSubMenu}) => {
  const backToPlanSubmenu = () => {
    setSelectedSubMenu('plan');
  };

  return (
    <div
      className={`${styles.container} ${
        selectedSubMenu === 'creditsInfo' ? styles.visible : ''
      } custom-scrollbar`}
    >
      <SubmenuHeading
        title="Credits Information"
        leftCtaTitle="Back"
        leftCtaAction={backToPlanSubmenu}
      />
      <div className={`${styles.main} sp1`}>
        <p>
          <b>What’s a Credit?</b>
        </p>
        <p>
          A credit is a token that allows you to generate entire spaces, images
          or texts.{' '}
        </p>
        <p>
          <b>How do I receive Credit?</b>
        </p>
        <p>
          When you create an account you receive 100 credits for free, and each
          time you invite a friend to join Paralllel, you receive an extra 50
          credits per invite.
        </p>
        <p>
          <b>What happens when I have used all my credits?</b>
        </p>
        <p>
          Once you’ve used all your credits, and if you want to generate more
          spaces, images or text, you will have to subscribe to our “Plus” plan
          to get unlimited credits.
        </p>
        <p>
          <b>How many credits are used to generate a space?</b>
        </p>
        <p>10</p>
        <p>
          <b>How many credits are used to generate an image?</b>
        </p>
        <p>1</p>
        <p>
          <b>How many credits are used to generate a text?</b>
        </p>
        <p>1</p>
      </div>
    </div>
  );
};

export default CreditsInfoSubmenu;
