import React, { useEffect } from 'react';

const useAnimate = (els, timeout, style) => {
  useEffect(() => {
    let timer = [];
    if (els && Array.isArray(els)) {
      els.forEach((el, i) => {
        timer[i] = setTimeout(() => {
          el?.classList.add(style);
        }, timeout);
      });
    }

    return () => {
      timer.forEach((timerElement) => {
        clearTimeout(timerElement);
      });
    };
  }, [els]);
};

export default useAnimate;
