import { useState, } from 'react';

import Generate from 'assets/icons/Generate';
import styles from 'assets/styles/modules/GenerateButton.module.scss';

const GenerateButton = ({action, square, }) => {
  const [clickDisabled, setClickDisabled] = useState(false);

  const handleClick = () => {
    if (!clickDisabled) action();
    setClickDisabled(true);
  }

  return (
    <button className={`${styles.container} ${square ? styles.square : styles.rounded} shadow-sm`} disabled={ clickDisabled } onClick={ handleClick }>
      <div className={styles.icon}>
        <Generate />
      </div>

      <span>Generate</span>
    </button>
  );
};

export default GenerateButton;
