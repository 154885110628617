import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/TextSection.module.scss';
import EditButton from './ui/EditButton';
import { motion } from 'framer-motion';
import CircleIcon from 'assets/icons/CircleIcon';
import Bin from 'assets/icons/Bin';
import Plus from 'assets/icons/Plus';
import { spring } from 'utilities/animations';
import { useState } from 'react';
import TextModal from 'components/common/Modal/Text/TextModal';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';

const TextSection = ({ content, isShowPage, openEdit, removeSection }) => {
  const { swipeHandlers, layout } = useSwipeHandlers(content?.variationNumber || 1);
  const [active, setActive] = useState(false);
  const [tempTitle, setTempTitle] = useState(null);
  const [tempText, setTempText] = useState(null);

  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section { ...(isShowPage ? [] : swipeHandlers) } className={ globalSectionStyles.container } draggable='false' style={{ userSelect: 'none' }}>
        <TextModal active={ active } content={ content } setActive={ setActive } setTempText={ setTempText } setTempTitle={ setTempTitle } tempText={ tempText } tempTitle={ tempTitle } />

        { openEdit &&
          <EditButton openModal={setActive} position='bottomRightNoImage' />
        }

        <div className={ `${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}` } data-layout={ layout === 0 ? 1 : layout }>
          <motion.h2 className={styles.heading} layout transition={spring}>
            {tempTitle ? tempTitle : content?.texts[0]}
          </motion.h2>

          <motion.div className={styles.text} layout transition={spring}>
            {tempText ? tempText : content?.texts[1]}
          </motion.div>
        </div>

        { removeSection &&
          <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        }

        { openEdit &&
          <div className={globalSectionStyles.addSection} onClick={ () => openEdit({ status: true, afterPosition: content.position }) }>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        }
      </section>

      { openEdit &&
        <div className={globalSectionStyles.divider}></div>
      }
    </>
  );
};

export default TextSection;
