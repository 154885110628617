import React from 'react';
import styles from 'assets/styles/modules/SocialData.module.scss';
import Heart from 'assets/icons/Heart';
import Eye from 'assets/icons/Eye';

function formatSocialNumber(num) {
  return num > 1000 ? `${Math.round(num / 1000 * 10) / 10}k` : num;
}

const SocialData = ({likes, views}) => {
  return (
    <div className={`font-semibold uppercase ${styles.container}`}>
      <div className={styles.data}>
        <span className={styles.ico}>
          <Heart color={'#f09c1d'} />
        </span>

        { formatSocialNumber(likes) || '-' }
      </div>

      <div className={styles.data}>
        <span className={styles.ico}>
          <Eye color={'#f09c1d'} />
        </span>

        { formatSocialNumber(views) || '-' }
      </div>
    </div>
  );
};

export default SocialData;
