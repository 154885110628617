import { useState, } from 'react';
import styles from 'assets/styles/modules/Modal.module.scss';
import ModalHeading from '../ModalHeading';
import Modal from '../Modal';
import { AnimatePresence } from 'framer-motion';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ModalBody from '../ModalBody';

const MakePublicConfirmationModal = ({ active, confirm, setActive, }) => {
  useScrollBlock(active);

  const close = () => {
    setActive(false);
  };

  return (
    <AnimatePresence>
      {active && (
        <Modal handleClose={ close }>
          <ModalHeading handleHeadingClose={ close } title="Public Mode" />

          <ModalBody>
            <div className={ styles.confirmationText } >
              <p>
                You are about to make your Space Public. This means your Space will be visible on the Feed to the entire OffSpace community.
              </p>

              <br />
              
              <p>
                You can always return this Space to Private if needed.
              </p>

              <br />

              <button className="button button--filled w-full" onClick={ confirm }>Make Public</button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default MakePublicConfirmationModal;
