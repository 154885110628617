const Plus = ({ color, }) => {
  return (
    <svg viewBox='0 0 11.31 11.31'>
      <path
        d='m6.41,4.91h4.16c.41,0,.75.34.75.75s-.34.75-.75.75h-4.16v4.16c0,.41-.34.75-.75.75s-.75-.34-.75-.75v-4.16H.75c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h4.16V.75c0-.41.34-.75.75-.75s.75.34.75.75v4.16Z'
        fill={ color ? color : '#fff' }
        fillRule='evenodd'
      />
    </svg>
  );
};

export default Plus;
