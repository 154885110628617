const SectionImages = () => {
  return (
    <svg width='56' height='79' viewBox='0 0 56 79'>
      <rect
        x='.5'
        y='.5'
        width='55'
        height='35'
        rx='1.5'
        ry='1.5'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <path
        d='m6.95,33.07l10.38-13.16c.56-.71,1.61-.77,2.24-.13l4.64,4.67c1.12,1.12,2.98.94,3.86-.38l10.77-16.28c.59-.9,1.91-.9,2.5,0l.42-.28-.42.28,13.92,21.03c.16.25.25.53.25.83v4.35c0,.83-.67,1.5-1.5,1.5H8.12c-1.25,0-1.95-1.44-1.18-2.43Z'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <circle
        cx='10.4'
        cy='11.01'
        r='3.16'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <rect
        x='.5'
        y='43.5'
        width='55'
        height='35'
        rx='1.5'
        ry='1.5'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <path
        d='m6.95,76.07l10.38-13.16c.56-.71,1.61-.77,2.24-.13l4.64,4.67c1.12,1.12,2.98.94,3.86-.38l10.77-16.28c.59-.9,1.91-.9,2.5,0l.42-.28-.42.28,13.92,21.03c.16.25.25.53.25.83v4.35c0,.83-.67,1.5-1.5,1.5H8.12c-1.25,0-1.95-1.44-1.18-2.43Z'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
      <circle
        cx='10.4'
        cy='54.01'
        r='3.16'
        fill='none'
        stroke='#2f2f2f'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SectionImages;
