import { useState } from 'react';
import useSwipe from './useSwipe';

const useSwipeHandlers = (currentLayout) => {
  const [layout, setLayout] = useState(currentLayout || 0);
  const [previousLayout, setPreviousLayout] = useState(0);

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      if (layout === 0) {
        setPreviousLayout(0);
        setLayout(3);
      } else if (layout === 3) {
        setPreviousLayout(3);
        setLayout(1);
      } else {
        setPreviousLayout(layout);
        setLayout(layout + 1);
      }
    },
    onSwipedRight: () => {
      if (layout === 0) {
        setPreviousLayout(0);
        setLayout(3);
      } else if (layout === 3) {
        setPreviousLayout(3);
        setLayout(1);
      } else {
        setPreviousLayout(layout);
        setLayout(layout + 1);
      }
    },
  });

  return {
    swipeHandlers,
    layout,
    previousLayout,
  };
};

export default useSwipeHandlers;
