import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { sendAsWsRequest, } from 'utilities/websockets';

import CreationFormNavigation from './CreationFormNavigation';
import CreationFormQuestions from './CreationFormQuestions';
import VideoBackground from './VideoBackground';
import useCustomer from 'lib/hooks/useCustomer';
import { useStore } from 'state/store';
import useRequireAuthenticated from 'lib/hooks/useRequireAuthenticated';

function CreateExperimentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useCustomer();
  const userData = useStore((state) => state.user);

  useRequireAuthenticated();

  const initalState = location.state || {};
  const data = {
    _id: userData.userId,
    username: userData.username,
  };

  useEffect(() => {
    setTimeout(clearFocus, 250);
  }, []);

  const [activeStep, setActiveStep] = useState(initalState.activeStep || 0);
  const [selectedDescription, setDescription] = useState('');
  const [selectedTitle, setTitle] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');

  // must be lower for initialization purposes
  useEffect(() => {
    pulseVideo();
    animateCurrentStep(activeStep);
  }, [activeStep]);

  function animateCurrentStep(stepNumber) {
    document
      .querySelectorAll(`.char.title-${stepNumber}`)
      .forEach((char, i) => {
        setTimeout(
          (char) => {
            char.classList.add('shown');
          },
          i * 15,
          char,
        );
      });
  }

  function clearFocus() {
    document
      .querySelectorAll(`.step_title.title-${activeStep}`)
      .forEach((elem) => elem.classList.remove('field-focused'));
    document
      .querySelectorAll('#video-background-container')
      .forEach((elem) => elem.classList.remove('field-focused'));
  }

  function goToNext() {
    if (isNextButtonActive()) {
      clearFocus();
      // move to new step
      setActiveStep(Math.min(activeStep + 1, 5));
      // submit if at end
      if (activeStep === 4) submitResponses();
    }
  }

  function goToPrevious() {
    if (activeStep > 0) {
      document
        .querySelectorAll(`.step_title.title-${activeStep}`)
        .forEach((elem) => elem.classList.remove('field-focused'));
      document
        .querySelectorAll('#video-background-container')
        .forEach((elem) => elem.classList.remove('field-focused'));

      setActiveStep(activeStep - 1);
    }
  }

  function goToStep(desiredStep) {
    setActiveStep(desiredStep);
  }

  function isNextButtonActive() {
    switch (activeStep) {
      case 0:
        return true;
      case 1:
        return !!selectedTitle;
      case 2:
        return !!selectedDescription;
      case 3:
        return true;
      case 4:
        return true;
      default:
        return true;
    }
  }

  function onFieldFocus() {
    document
      .querySelectorAll(
        `.step_title.title-${activeStep}:not([class*="field-focused"]`,
      )
      .forEach((elem) => elem.classList.add('field-focused'));
    document
      .querySelectorAll(
        '#video-background-container:not([class*="field-focused"]',
      )
      .forEach((elem) => elem.classList.add('field-focused'));
  }

  function pulseVideo() {
    setTimeout(() => {
      document
        .querySelectorAll('#video-background-container:not([class*="pulsing"]')
        .forEach((elem) => {
          elem.classList.add('pulsing');
          setTimeout(() => elem.classList.remove('pulsing'), 400);
        });
    }, 100);
  }

  async function showLoading(generatedIdLocation) {
    // await new Promise((resolve) => setTimeout(resolve, 750));

    const circleSvgElem = document.getElementById('circle-progress-svg');
    const percentageTextElem = document.getElementById('circle-progress-text');

    const initialValue = Number((window.getComputedStyle(circleSvgElem).strokeDashoffset || '0px').split('px')[0]);
    const timeoutPerTick = 90000 / initialValue; // total of 1.5 minutes

    // state doesn't update while in here -- makes it difficult to check when something is actually done...
    for (let i = initialValue; i > 0; i--) {
      circleSvgElem.style.strokeDashoffset = `${i}px`;
      let percentageCompletion = Math.round(100 - (i / initialValue) * 100);
      percentageTextElem.innerHTML = `${percentageCompletion}%`;

      let timeoutLength = localStorage.getItem(generatedIdLocation) ? 1 : (timeoutPerTick * ((percentageCompletion + 1) / 50));      
      await new Promise((resolve) => setTimeout(resolve, timeoutLength));
    }

    for (let i = 0; i <= initialValue + 10; i += 10) {
      circleSvgElem.style.strokeDashoffset = `${-i}px`;
      await new Promise((resolve) => setTimeout(resolve, 0));
    }

    document.getElementById('generation-circle').classList.add('form-submitted');
    await new Promise((resolve) => setTimeout(resolve, 250));

    document.getElementById('video-background-container').classList.add('form-submitted');
    await new Promise((resolve) => setTimeout(resolve, 400));

    // stall until generation is completed (it should complete around 65% above)
    while (!localStorage.getItem(generatedIdLocation)) {
      await new Promise((resolve) => setTimeout(resolve, 15));
    }

    navigate(`/spaces/${localStorage.getItem(generatedIdLocation)}/edit`);
  }

  async function submitResponses() {
    const generatedIdLocation = Math.random().toString(36).substring(2) + Date.now().toString(36);
    showLoading(generatedIdLocation);

    const body = {
      selectedDescription,
      selectedTitle,
      uploadedImageUrl,
      userId: data._id,
    };

    const creationResponse = await sendAsWsRequest('v1/experiments/create', body);
    localStorage.setItem(generatedIdLocation, creationResponse._id);
  }

  return (
    <section className='main'>
      <CreationFormNavigation activeStep={activeStep} />

      <CreationFormQuestions
        activeStep={activeStep}
        description={ selectedDescription }
        goToStep={ goToStep }
        onFieldFocus={onFieldFocus}
        setDescription={setDescription}
        setStep={setActiveStep}
        setTitle={setTitle}
        setUploadedImageUrl={ setUploadedImageUrl }
        submitResponses={submitResponses}
        title={ selectedTitle }
        user={data}
        uploadedImageUrl={ uploadedImageUrl }
      />

      <div className='formActions'>
        <button className={`button button--nav navPrev ${activeStep === 5 ? 'hidden' : ''}`} onClick={goToPrevious}>
          <svg
            width='16'
            height='16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M5.568 7.25h6.682a.75.75 0 0 1 0 1.5H5.568L8 11.183a.75.75 0 1 1-1.061 1.061L3.403 8.708a1 1 0 0 1 0-1.415L6.94 3.758a.75.75 0 0 1 1.06 1.06L5.569 7.252Z'
            />
          </svg>
        </button>

        <button className={`button button--nav navNext ${activeStep === 5 ? 'hidden' : ''} ${isNextButtonActive() ? 'button--filled' : ''}`} onClick={goToNext}>
          <svg
            width='16'
            height='16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M10.432 8.75H3.75a.75.75 0 0 1 0-1.5h6.682L8 4.818a.75.75 0 1 1 1.06-1.06l3.536 3.535a1 1 0 0 1 0 1.414l-3.535 3.536A.75.75 0 0 1 8 11.183l2.432-2.433Z'
            />
          </svg>
        </button>
      </div>

      <VideoBackground selectedStyle={'minimal'} />
    </section>
  );
}

export default CreateExperimentPage;
