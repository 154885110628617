import { useRef } from 'react';
import { useInView } from 'framer-motion';

const AnimateWhenVisible = ({ children, delay }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? 'none' : 'translateY(-20px)',
          opacity: isInView ? 1 : 0,
          transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${
            delay ? `${delay}s` : 0
          }`,
        }}
      >
        {children}
      </span>
    </section>
  );
};

export default AnimateWhenVisible;
