import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useCustomer from 'lib/hooks/useCustomer';

import styles from 'assets/styles/modules/NavBar.module.scss';
import Avatar from '../Avatar';

const NavBar = ({ menuIsOpen, toggleMenu }) => {
  const navigate = useNavigate();
  const user = useCustomer();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <span className={styles.inner}>
        {user.state.isSignedIn() ? (
          <a className={ styles.avatar } onClick={ () => navigate((location.pathname == '/feed' || location.pathname == '/dashboard') ? '/' : '/dashboard?section=spaces') }>
            <Avatar img={ user.state.user.avatar } name={ user.state.user.username }  />
          </a>
        ) : (
          <a className={ styles.logo } onClick={ () => navigate('/') }></a>
        )}
        {user.state.isSignedIn() ? (
          location.pathname.includes('/edit') ? (
            <button className={ styles.closeButton } onClick={ () => navigate(-1) } type='button'>
              <span></span>
              <span></span>
              <span></span>
            </button>
          ) : (
            <button className={ `${styles.menuButton} ${menuIsOpen ? styles.opened : ''}` } onClick={ toggleMenu } type='button'>
              <span></span>
              <span></span>
              <span></span>
            </button>
          )
        ) : (
          <button className={styles.login} onClick={() => navigate('/sign-in')}>
            Sign In
          </button>
        )}
      </span>
    </div>
  );
};

export default NavBar;
