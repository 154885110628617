import { motion } from 'framer-motion';
import { useState } from 'react';

import useSelectTemplate from 'lib/hooks/useSelectTemplate';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';

import Bin from 'assets/icons/Bin';
import CircleIcon from 'assets/icons/CircleIcon';
import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import Plus from 'assets/icons/Plus';
import styles from 'assets/styles/modules/GallerySection.module.scss';
import { spring } from 'utilities/animations';

import EditButton from './ui/EditButton';
import GalleryModal from 'components/common/Modal/Gallery/GalleryModal';

const GallerySection = ({ content, isShowPage, openEdit, removeSection }) => {
  const [active, setActive] = useState(false);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  const [tempImages, setTempImages] = useState(['', '', '', '']);
  const [tempText, setTempText] = useState(null);
  const [tempBodyText, setTempBodyText] = useState(null);
  useSelectTemplate(content, layout === 0 ? 1 : layout);

  return (
    <>
      <section className={globalSectionStyles.container} draggable='false' style={{ userSelect: 'none' }}>
        <GalleryModal
          active={ active }
          content={ content }
          setActive={ setActive }
          setTempBodyText={ setTempBodyText }
          setTempImages={ setTempImages }
          setTempText={ setTempText }
          tempBodyText={ tempBodyText }
          tempImages={ tempImages }
          tempText={ tempText }
        />

        { openEdit &&
          <EditButton openModal={setActive} position='bottomRightNoImage' />
        }

        <div className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`} data-layout={layout === 0 ? 1 : layout}>
          <motion.div className={styles.gallery} layout transition={spring}>
            {content.images.map((image, i) => (
              <div className={styles.image} key={`gallery___${i}`}>
                <img alt={ content.texts[0] } className='rellax-element' draggable='false' key={i} src={ tempImages[i] || image } />
              </div>
            ))}
          </motion.div>

          <h1 { ...(isShowPage ? [] : swipeHandlers) } className={styles.heading}>
            <div className={styles.headingInner}>
              {Array(5).fill().map((item, i) => (
                  <span key={`array__${i}`}>
                    {tempText ? tempText : content.texts[0]}
                  </span>
                ))}
            </div>
          </h1>

          <motion.ul className={styles.dots} layout transition={spring}>
            { Array(4).fill().map((item, i) => ( <li key={`array2__${i}`}></li> )) }
          </motion.ul>

          <motion.div { ...(isShowPage ? [] : swipeHandlers) } className={styles.text} layout transition={spring}>
            {tempBodyText ? tempBodyText : content.texts[1]}
          </motion.div>
        </div>

        { removeSection &&
          <div className={globalSectionStyles.delete} onClick={() => removeSection(content.rowId)}>
            <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
              <Bin />
            </CircleIcon>
          </div>
        }

        { openEdit &&
          <div className={globalSectionStyles.addSection} onClick={ () => openEdit({ status: true, afterPosition: content.position }) }>
            <CircleIcon iconWidth={'w-12'}>
              <Plus />
            </CircleIcon>
          </div>
        }
      </section>

      { openEdit &&
        <div className={globalSectionStyles.divider}></div>
      }
    </>
  );
};

export default GallerySection;
