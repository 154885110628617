import globalSectionStyles from 'assets/styles/modules/Section.module.scss';
import styles from 'assets/styles/modules/ContactSection.module.scss';
import CircleIcon from 'assets/icons/CircleIcon';
import Bin from 'assets/icons/Bin';
import Plus from 'assets/icons/Plus';
import { motion, useAnimation } from 'framer-motion';
import { spring } from 'utilities/animations';
import EditButton from './ui/EditButton';
import { useEffect, useState } from 'react';
import useSwipeHandlers from 'lib/hooks/useSwipeHandlers';
import useSelectTemplate from 'lib/hooks/useSelectTemplate';
import ContactModal from 'components/common/Modal/Contact/ContactModal';

const ContactSection = ({ content, isShowPage, openEdit, removeSection }) => {
  const [active, setActive] = useState(false);
  const [currentLayout, setCurrentLayout] = useState(0);
  const { swipeHandlers, layout } = useSwipeHandlers(content.variationNumber);
  const [tempTitle, setTempTitle] = useState(null);
  const [tempText, setTempText] = useState(null);
  const [tempLinkUrl, setTempLinkUrl] = useState(null);
  const [tempLinkTitle, setTempLinkTitle] = useState(null);
  const ctrls = useAnimation();
  useSelectTemplate(content, currentLayout);

  const headingAnimation = {
    hidden: {
      opacity: 0,
      y: '100%',
      transition: {
        duration: 0.2,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    one: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    two: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    three: {
      opacity: 1,
      y: 0,
      rotate: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  useEffect(() => {
    // Initial state will be "hidden"
    ctrls.start('hidden');

    // We must delay the layout change in order to allocate some time to perform the reveal animations
    const t1 = setTimeout(() => {
      setCurrentLayout(layout === 0 ? 1 : layout);
    }, 250);

    // We now proceed to animate the heading based on the current layout selection
    let t2;
    if (layout === 1) {
      t2 = setTimeout(() => {
        ctrls.start('one');
      }, 450);
    }
    if (layout === 2) {
      t2 = setTimeout(() => {
        ctrls.start('two');
      }, 450);
    }
    if (layout === 3) {
      t2 = setTimeout(() => {
        ctrls.start('three');
      }, 450);
    }

    return () => {
      // Timeouts cleanup
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, [layout]);

  return (
    <>
      <section
        className={globalSectionStyles.container}
        { ...(isShowPage ? [] : swipeHandlers) }
        draggable='false'
        style={{ userSelect: 'none' }}
      >
        <ContactModal
          active={active}
          setActive={setActive}
          content={content}
          setTempTitle={setTempTitle}
          setTempText={setTempText}
          setTempLinkTitle={setTempLinkTitle}
          setTempLinkUrl={setTempLinkUrl}
        />
        <EditButton openModal={setActive} position='bottomRightNoImage' />
        <motion.div
          className={`${isShowPage ? globalSectionStyles.contentFullPageView : globalSectionStyles.contentEditView} ${styles.content}`}
          data-layout={currentLayout}
          layout
          transition={spring}
        >
          <motion.h2 className={styles.heading}>
            <div style={{ overflow: 'hidden' }} className={styles.rotate}>
              <motion.div
                initial='hidden'
                animate={ctrls}
                variants={headingAnimation}
              >
                {tempTitle
                  ? tempTitle
                  : content?.texts[0]
                  ? content?.texts[0]
                  : 'KEEP IN TOUCH'}
              </motion.div>
            </div>
          </motion.h2>
          <div className={styles.main}>
            <motion.h3 className={styles.subHeading} layout transition={spring}>
              Connect
            </motion.h3>
            <ul className={styles.list}>
              {tempLinkUrl ? (
                tempLinkUrl.map((link, i) => (
                  <li>
                    <a href={link.href}>{tempLinkTitle[i]}</a>
                  </li>
                ))
              ) : content?.texts[2] ? (
                content?.texts[2]?.map((link, i) => (
                  <li>
                    <a href={link.href}>{content?.texts[3][i]}</a>
                  </li>
                ))
              ) : (
                <>
                  <li>
                    <a href={'#'}>{`Instagram`}</a>
                  </li>
                  <li>
                    <a href={'#'}>{`Twitter`}</a>
                  </li>
                  <li>
                    <a href={'#'}>{`Facebook`}</a>
                  </li>
                </>
              )}
            </ul>
            <motion.h3 className={styles.subHeading} layout transition={spring}>
              Contact
            </motion.h3>
            <div className={styles.list}>
              {tempText ? tempText : content?.texts[1] ? content.texts[1] : ''}
            </div>
          </div>
        </motion.div>
        <div
          className={globalSectionStyles.delete}
          onClick={() => removeSection(content.rowId)}
        >
          <CircleIcon variant={'filledInverted'} iconWidth={'w-14'}>
            <Bin />
          </CircleIcon>
        </div>
        <div
          className={globalSectionStyles.addSection}
          onClick={() =>
            openEdit({ status: true, afterPosition: content.position })
          }
        >
          <CircleIcon iconWidth={'w-12'}>
            <Plus />
          </CircleIcon>
        </div>
      </section>
      <div className={globalSectionStyles.divider}></div>
    </>
  );
};

export default ContactSection;
