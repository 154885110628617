import styles from 'assets/styles/modules/DashboardCard.module.scss';

const DashboardCard = ({children}) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.card} shadow-md`}>{children}</div>
    </div>
  );
};

export default DashboardCard;
