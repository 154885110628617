import styles from 'assets/styles/modules/ModalSectionType.module.scss';

const ModalSectionType = ({children, text}) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.icon} shadow-md`}>{children}</div>

      <div className="sp1">{text}</div>
      
      <div className="font-semibold">+Add</div>
    </div>
  );
};

export default ModalSectionType;
