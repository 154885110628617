const Bin = ({color}) => {
  return (
    <svg viewBox="0 0 14 16">
      <path
        d="m8.96,1c.03-.08.04-.16.04-.25,0-.41-.34-.75-.75-.75h-2.5c-.41,0-.75.34-.75.75,0,.09.02.17.04.25H.75c-.41,0-.75.34-.75.75s.34.75.75.75h.51l.63,12.79c.02.4.35.71.75.71h8.73c.4,0,.73-.31.75-.71l.63-12.79h.51c.41,0,.75-.34.75-.75s-.34-.75-.75-.75h-4.29Zm-6.19,1.5h8.47l-.59,12H3.35l-.59-12Zm2.74,10.5c-.41,0-.75-.34-.75-.75v-7.5c0-.41.34-.75.75-.75s.75.34.75.75v7.5c0,.41-.34.75-.75.75Zm2.25-.75c0,.41.34.75.75.75s.75-.34.75-.75v-7.5c0-.41-.34-.75-.75-.75s-.75.34-.75.75v7.5Z"
        fill={color ? color : '#1c1c1c'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Bin;
