import { useEffect, useState } from 'react';
import { useStore } from 'state/store';

const useAvatar = () => {
  const [avatar, setAvatar] = useState(null);
  const userData = useStore((state) => state.user);

  useEffect(() => {
    if (userData) {
      setAvatar(userData.avatar);
    }
  }, [userData]);

  return avatar;
};

export default useAvatar;
