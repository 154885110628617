import React, {useState} from 'react';
import SubmenuHeading from './SubmenuHeading';
import styles from 'assets/styles/modules/Submenu.module.scss';

const LanguageSubmenu = ({selectedSubMenu, closeSubmenu}) => {
  const [userLanguage, setUserLanguage] = useState('english');

  return (
    <div
      className={`${styles.container} ${
        selectedSubMenu === 'language' ? styles.visible : ''
      } custom-scrollbar`}
    >
      <SubmenuHeading
        title="Language"
        leftCtaTitle="Back"
        leftCtaAction={closeSubmenu}
      />

      <div className="formRow formRow--radio">
        <label className="formRow_label radio-label">
          English
          <input
            type="radio"
            name="lang"
            checked={userLanguage === 'english'}
            onChange={() => setUserLanguage('english')}
          />
          <span className="check"></span>
        </label>
      </div>

      {/* <div className="formRow formRow--radio">
        <label className="formRow_label radio-label disabled">
          Français
          <input
            type="radio"
            name="lang"
            checked={userLanguage === 'french'}
            onChange={() => setUserLanguage('french')}
          />
          <span className="check"></span>
        </label>
      </div>

      <div className="formRow formRow--radio">
        <label className="formRow_label radio-label disabled">
          Español
          <input
            type="radio"
            name="lang"
            checked={userLanguage === 'spanish'}
            disabled
            onChange={() => setUserLanguage('spanish')}
          />
          <span className="check"></span>
        </label>
      </div> */}
    </div>
  );
};

export default LanguageSubmenu;
