import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, } from 'react-router-dom';

import styles from 'assets/styles/modules/EditPage.module.scss';
import useExperimentEditData from 'lib/hooks/useExperimentEditData';
import useExperimentSections from 'lib/hooks/useExperimentSections';
import useRequireAuthenticated from 'lib/hooks/useRequireAuthenticated';

import AboutSection from './AboutSection';
import AnimateWhenVisible from 'components/common/AnimateWhenVisible';
import ContactSection from './ContactSection';
import CtaSection from './CtaSection';
import EditActions from './ui/EditActions';
import FooterSection from './FooterSection';
import GallerySection from './GallerySection';
import HomeSection from './HomeSection';
import ImagesSectionThree from './ImagesSectionThree';
import NavBarSection from './NavBarSection';
import PlaceholderSection from './PlaceholderSection';
import QuoteSection from './QuoteSection';
import ScoreSection from './ScoreSection';
import SectionsModal from 'components/common/Modal/Sections/SectionsModal';
import TextSection from './TextSection';
import VideoSection from './VideoSection';

const Edit = () => {
  const navigate = useNavigate();
  const { experimentId } = useParams();
  const sections = useExperimentSections();
  const experimentEditData = useExperimentEditData();
  const { templateVerticalId } = experimentEditData;
  const [footerTitle, setFooterTitle] = useState('');
  const [sectionsModalIsOpen, setSectionsModalIsOpen] = useState({
    status: false,
    afterPosition: 0,
  });
  const [contents, setContents] = useState(null);
  const updated = useRef(null);


  useEffect(() => {
    async function stubForAsync() {
      const pageContents = await experimentEditData.fetchPageContents(experimentId);
      const pageTitle = pageContents.filter((content) => content.sectionType === 'navBar')[0]?.texts[0];

      setContents(pageContents);
      setFooterTitle(pageTitle);
    }
    stubForAsync();
  }, [experimentId, ]);

  useRequireAuthenticated();

  const handleAddSection = async (sectionType, desiredPosition) => {
    const contentsWithStub = [...contents.filter((content) => content.position < desiredPosition), { position: desiredPosition, sectionType: 'placeholder', }, ...contents.filter((content) => content.position >= desiredPosition)];
    setContents(contentsWithStub);

    await sections.createSection(sectionType, templateVerticalId, desiredPosition, experimentId);
    const updatedContents = await experimentEditData.fetchPageContents(experimentId);
    setContents(updatedContents);

    updated.current = true;
  };

  const handleRemoveSection = (rowId) => {
    if (rowId) {
      const sectionsAfterDelete = contents.filter((section) => section.rowId !== rowId);
      const updatedSections = sectionsAfterDelete.map((section, i) => {
        return { ...section, position: i };
      });
      // Update contents
      setContents(updatedSections);

      // Call API and remove
      sections.removeSection(rowId, experimentId);
    }
  };

  return (
    <div className='main scroll'>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {contents?.map((pageContent, i) => {
              return ({
                navBar: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.2}>
                    <NavBarSection content={pageContent} />
                  </AnimateWhenVisible>
                ),
                home: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.4}>
                    <HomeSection content={pageContent} openEdit={setSectionsModalIsOpen} />
                  </AnimateWhenVisible>
                ),
                about: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.9}>
                    <AboutSection content={pageContent} openEdit={setSectionsModalIsOpen} removeSection={handleRemoveSection} />
                  </AnimateWhenVisible>
                ),
                quote: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.9}>
                    <QuoteSection content={pageContent} openEdit={setSectionsModalIsOpen} removeSection={handleRemoveSection} />
                  </AnimateWhenVisible>
                ),
                gallery: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <GallerySection
                      content={pageContent}
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                    />
                  </AnimateWhenVisible>
                ),
                score: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <ScoreSection
                      content={pageContent}
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                    />
                  </AnimateWhenVisible>
                ),
                text: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <TextSection
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                      content={pageContent}
                    />
                  </AnimateWhenVisible>
                ),
                video: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <VideoSection
                      content={pageContent}
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                    />
                  </AnimateWhenVisible>
                ),
                images: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <ImagesSectionThree
                      content={pageContent}
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                    />
                  </AnimateWhenVisible>
                ),
                products:
                  null /*<AnimateWhenVisible key={i}><ProductsSection content={pageContent} /></AnimateWhenVisible>*/,
                contact: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <ContactSection
                      content={pageContent}
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                    />
                  </AnimateWhenVisible>
                ),
                button: (
                  <AnimateWhenVisible key={ pageContent.rowId } delay={0.3}>
                    <CtaSection
                      content={pageContent}
                      openEdit={setSectionsModalIsOpen}
                      removeSection={handleRemoveSection}
                    />
                  </AnimateWhenVisible>
                ),
                placeholder: (
                  <AnimateWhenVisible key={`placeholder${i}__${i}`} delay={0.9}>
                    <PlaceholderSection />
                  </AnimateWhenVisible>
                ),
              }[pageContent.sectionType]);
            }
          )}

          {contents?.length > 0 &&
            <>
              <AnimateWhenVisible delay={1.8}>
                <FooterSection title={footerTitle} />
              </AnimateWhenVisible>

              <AnimateWhenVisible delay={1.8}>
                <div className={`${styles.ctaSave} button button--filled w-full`}onClick={ () => navigate('/dashboard') }>
                  Save & Close
                </div>
              </AnimateWhenVisible>
            </>
          }

          <SectionsModal active={ sectionsModalIsOpen } addSection={ handleAddSection } setActive={ setSectionsModalIsOpen } />
        </div>
      </div>
      <EditActions experimentId={experimentId} />
    </div>
  );
};

export default Edit;
