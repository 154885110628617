import { useEffect } from 'react';
import { useStore } from 'state/store';

const useRequireAuthenticated = () => {
  const user = useStore((state) => state.user);

  const isSignedIn = () => {
    return user.userId !== null && user.userId !== undefined;
  };

  /* Upon mount, check to see if user is signed in */
  useEffect(() => {
    if (!isSignedIn()) {
      localStorage.setItem(
        'message-for-user',
        'You must be signed in to access that page.',
      );
      window.location.assign('/');
    }
  }, []);

  return;
};

export default useRequireAuthenticated;
