const ArrowRightAngle = ({color}) => {
  return (
    <svg viewBox="0 0 7.51 7.51">
      <path
        d="m6.01,2.56L1.28,7.29c-.29.29-.77.29-1.06,0-.29-.29-.29-.77,0-1.06L4.94,1.5H1.51c-.41,0-.75-.34-.75-.75s.34-.75.75-.75h5c.55,0,1,.45,1,1v5c0,.41-.34.75-.75.75s-.75-.34-.75-.75v-3.44Z"
        fill={color ? color : '#fff'}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ArrowRightAngle;
