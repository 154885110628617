import React from 'react';
import GenerateButton from '../Buttons/GenerateButton';
import styles from 'assets/styles/modules/FormEditImage.module.scss';
import FormImageEnhance from './FormImageEnhance';
import FormImageAddMultiple from './FormImageAddMultiple';

const FormEditImageMultiple = ({
  errors,
  generate,
  img,
  multiple,
  rowId,
  setFieldValue,
  setTempImages,
  slot,
  tempImages,
  touched,
}) => {
  return (
    <div className={`${styles.container} ${multiple ? styles.multiple : ''}`}>
      <FormImageAddMultiple
        id='files'
        title=''
        errors={errors}
        touched={touched}
        img={img}
        rowId={ rowId }
        setFieldValue={setFieldValue}
        setTempImages={setTempImages}
        tempImages={tempImages}
        slot={slot}
        mutiple={true}
      />
      <div className={styles.col}>
        {/* <FormImageEnhance /> */}
        <GenerateButton square action={ () => !!generate ?  generate(img) : null } />
      </div>
    </div>
  );
};

export default FormEditImageMultiple;
