const Bookmark = () => {
  return (
    <svg viewBox="0 0 12 15.51">
      <path
        d="m6,7.19l4.6,5.79V1.4H1.4v11.59l4.6-5.79Zm4.57,8.01c.47.59,1.43.26,1.43-.5V.8c0-.44-.36-.8-.8-.8H.8c-.44,0-.8.36-.8.8v13.91c0,.76.96,1.09,1.43.5l4.57-5.76,4.57,5.76Z"
        fill="#2f2f2f"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Bookmark;
