import React from 'react';
import styles from 'assets/styles/modules/Submenu.module.scss';

const SubmenuHeading = ({
  title,
  leftCtaTitle,
  leftCtaAction,
  rightCtaTitle,
  rightCtaAction,
}) => {
  return (
    <div className={styles.heading}>
      <button className={styles.left} onClick={leftCtaAction}>
        {leftCtaTitle}
      </button>
      <h2 className={styles.title}>{title}</h2>
      {rightCtaTitle && (
        <button className={styles.right} onClick={rightCtaAction}>
          {rightCtaTitle}
        </button>
      )}
    </div>
  );
};

export default SubmenuHeading;
